import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { Location } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
  FormArray,
  AbstractControl
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { COMMA, ENTER, V, X } from "@angular/cdk/keycodes";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from "@angular/material/legacy-autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, of } from "rxjs";
// import {
//   setInterval,
//   clearInterval
// } from 'timers';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { NgxUiLoaderService } from "ngx-ui-loader";
import * as XLSX from "xlsx";
import { MatSelect } from "@angular/material/select";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatOption } from "@angular/material/core";

import { CreateComponent } from "src/app/create/create.component";
import { CommonService } from "src/app/services/common.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SavevendorComponent } from "src/app/vendor/Savevendor/savevendor.component";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImportService } from "src/app/services/importResolver.service";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Routeversion1Component } from "src/app/routeversion1/routeversion1.component";
import { MailComponent } from "src/app/mail/mail.component";
import { NewauditComponent } from '../../newaudit/newaudit.component';
import { HttpClient } from "@angular/common/http";
declare var $;
declare const copyToClipboard: any;
interface ConnectivityType {
  viewValue: string;
  value: string;
}
type AOA = any[][];
@Component({
  selector: "app-request-form",
  templateUrl: "./request-form.component.html",
  styleUrls: ["./request-form.component.scss"],
  // encapsulation: ViewEncapsulation.None,
})
export class RequestFormComponent implements OnInit {
  isValidJson:boolean=false;
  venMnemonic: string
  vendoName: string
  vendoID: any
  openvendorshowImp: boolean
  venMnemonicImp: string
  vendoNameImp: string
  vendoIDImp: any
  openvendorshowExp: boolean
  venMnemonicExp: string
  vendoNameExp: string
  vendoIDExp: any
  venID: string
  venName: string
 venImpID: string
 venImpName: string
  venExpID: string
  venExpName: string
  projImpID: string ='';
  ImpID: any
  isEditLabExpGUID: string
  labExpContID: any
  labExpId: number
  reqExpId: any
  venContExpId: any
  venExpId: number
  isEditLabImpGUID: string
  labimpContID: any
  labimpId: number
  reqImpId: any
  venContId: any
  vencontimpId: number
  errorRecordsFilename:string =''
  pendingProjectCount:number=0
  sendmail:boolean=false;
  projectid:string="";
  exportStatus :string=''
  showProjectSubmitButton:boolean=true;
  ImpDeploymentKey:string='';
  ExpDeploymentKey
  ModalTitle:string='';
  ShowApproveAndRejectButton:boolean=false
  NotesDeatils:any[]
  projectidPid:string=''
  submitbutton:string ='';
  approvalStatus:string=""
  showapprovebutton:boolean=true
  approvalprojectid:string="";
  pagerolepermisions: any = [];
  content:any="";
  showApprovalcard:boolean =true;
  ftpbase64:any
isftpmail:boolean
selectedIndexTab=0;
isedit: boolean = false;
public reactiveFormvendar: FormGroup;
public reactiveFormvendarImp: FormGroup;
public reactiveFormvendarExp: FormGroup;
notfounddata3: boolean;
notfounddataVenImp: boolean
processorlist: any;
labcnamelist: any = [];
filecontentastext: any = [];
filecontent: string;
fileName: string;
deleteFile: string
ItypedirectionIN: string
ItypedirectionOUT: string
ContType = [];
contLookupid: string
jobsubtypelist:any[]=[];
oobj = [];
config: string;
PrFileid: number;
fileid: string;
vpnexftptype = {};
apinonevaftpType = {};
batchCount: any
fileList = []
batchIndiStatus = []
batchStatus: any
viewImport = []
viewImpLab = []
viewImpReq = []
viewVendorImp = []

viewexport = []
viewexpLab = []
viewexpReq = []
viewVendorexp = []

projid: string;
projvaid: string;
projtype: string;
base64content:any
key:any
mainSubject = new FormControl('');
excel64responsebody:any
filesList = [];
isLockedVer1: any;
isChecked: Boolean;
DeviceList:any[]=[];
DeviceListbackup:any[]=[];
vbagentlist:any[]=[];
vbagentlistbackup:any[]=[];

prNameList = []
interlist=[]
excelbase64:any
sourcedetails:any
isproject:string
  pdf64responsebody:any
  htmlContent:any

projExpID: any
ExpID: any
LabImportControl = new FormControl("", Validators.required);
LabExportControl = new FormControl("", Validators.required);
vendorNameImpControl = new FormControl("", Validators.required);
vendorNameExpControl = new FormControl("", Validators.required);
vendorNameControl = new FormControl("", Validators.required);
// vendorNameSelect = new FormControl("");
facilityControl = new FormControl("", Validators.required);
facilityAddressControl = new FormControl("", Validators.required);
labControl = new FormControl("", Validators.required);
payinglabControl = new FormControl("", Validators.required);
MsgTypeControlin = new FormControl("");
MsgTypeControlout = new FormControl("");

senderagentControlin = new FormControl("");
senderagentControlout = new FormControl("");
processoragentControlin = new FormControl("");
processoragentControlout = new FormControl("");
destinationagentControlin = new FormControl("");
destinationagentControlout = new FormControl("");
filenamedisplay:string='result3.pdf'

Cclist: any = [
];
Cctrl = new FormControl('');
filteredCcid: Observable<string[]>;
allCcid: any = [
];
flag: boolean;
flag2: boolean;
isdevice:boolean;
ShowSubmitButton:boolean=false;
selectedvalue: string = "";
selectedLabImpvalue: string = "";
selectedLabExpvalue: string = "";
Labselected = new FormControl("");
LabImpselected = new FormControl("");
LabExpselected = new FormControl("");
selectedpaylabvalue: string = "";
payLabselected = new FormControl("");
Facilityselected = new FormControl("");
notfounddataImp: boolean
notfounddataExp: boolean
processorapilistin: any;
listvalidatein: boolean;
senderanddestinjson: any;
senderanddestinjsonid: any;
senderanddestinjsonpipelineuniqueid: any;
processoridin: any;
Importvalue:any;
facaddr1: string
facaddr2: string
isLabImpLoading: boolean
selectedlabImpobj: any
selectedlabExpobj: any
isLabExpLoading: boolean
displaydeployImp: any
displaydeployExp: any
labNameExpList = <any>[];
selecteddevicename:string=''
msgtypelistin: any;
msgtypelistout: any;
totalprocessdatain: any;
totalprocessdataout: any;
listvalidateout: boolean;
processorapilistout: any;
senderanddestoutjson: any;
senderanddestoutjsonid: any;
senderanddestoutjsonpipelineuniqueid: any;
processoridout: any;
Subject = new FormControl('');
/////////////////////////////////////////tomatchip
addOnBlur = true
readonly separatorKeysCodes: number[] = [ENTER, COMMA];
toaddresslist: any = [
];
fromaddress: string = 'VitalAxis@starmarkit.com'

toaddressctrl = new FormControl('');
filteredtoaddressid: Observable<string[]>;

alltoaddressid: any = []
editorConfig: AngularEditorConfig = {
  editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ],
  uploadWithCredentials: false,
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    ['link'],
    ['unlink'],
    ['insertImage'],
    ['insertVideo'],
    ['insertHorizontalRule'],
    ['removeFormat'],
  ]
};
  _file: SafeUrl;
  html2='<h4>hi<h4>';
  html=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,</span></span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Please find the attached file for FTP Information. </span></span></span></span></p>
  <p>&nbsp;</p>
  <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:10.0000pt"><span style="font-family:Calibri">Note: Attached file is password protected for the security purposes. Please refer to the following email to locate the password.</span></span></span></span></p>
  <p>&nbsp;</p>
  <p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>Thanks &amp; Regards</strong></span></span></strong></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>VitalAxis</strong></span></span></strong></span></span></p>`
   mails = {
    "shilpa.h@starmarkit.com": "shilpa.h"
  }
  @ViewChild("fileInput", { static: false }) myFileInput;
  @ViewChild("select", { static: false }) select: MatSelect;
  @ViewChild('tomailInput', { static: true }) tomailInput: ElementRef<HTMLInputElement>;
  @ViewChild('CcInput', { static: true }) CcInput: ElementRef<HTMLInputElement>;
  @ViewChild('closebutton', { static: false }) closebutton;
  openvendorshow: boolean;
  separatorKeysCodeslabs: number[] = [ENTER, COMMA];
  LabCtrl = new FormControl("");
  projectNameGroup : FormGroup;
  filteredLabs: Observable<string[]>;
  Labs: string[] = [];
  projFiles = new Array();
  allFacility: string[] = [];
  payingLabs: string[] = [];
  allLabs: string[] = ["Lab1", "Lab2", "Lab3", "Lab4", "Lab5"];
  allLabsImp: string[] = []
  allLabsExp: string[] = []
  viewdata: any;
  ViewInterfaceData = [];
  importDataList = [];
  exportDataList = [];
  isTabName: any = '';
  @ViewChild("LabInput", { static: true }) LabInput: any;
  title = "InterfaceRequestForm";
  formdata: any;
  isLoadingVendorName: Boolean = false;
  isLoadingVendorNameImp: Boolean = false;
  display: any = "hi";
  vendarnamevalue: string = "";
  vendarnamevalueImp: string = "";
  vendarnamevalueExp: string = "";
  vendorid: any;
  vendoridImp: any;
  modelradio: any = {};
  VendorsList = [];
  VendorsListImp = [];
  VendorsListExp = [];
  VendornameBackuplist: any;
  VendornameBackuplistImp: any;
  VendornameBackuplistExp: any;
  keywordsearchvendor = "name";

  objvendorid: number;
  objid: number;
  interfaceFormIdResponse: any;

  selectedlabobj: any;
  selectedpaylabobj: any;
  selectedfacilityobj: any;
  deploy = new FormControl("");
  displaydeploy: any;
  displaydeploy2: any;
  isLabImportLoading: boolean;
  isLabLoading: boolean;
  isPLabLoading: boolean;
  isFacilityLoading: boolean;
  isMsginLoading: boolean;
  isProcessorinLoading: boolean;
  isMsgoutLoading: boolean;
  isProcessoroutLoading: boolean;
  obj:any
  isInbound: boolean;
  isOutbound: boolean;
  isjsonData = [];
  isEditLabGUID: any;
  isEditFaciltyGUID: any;
  isEditPayingGUID: any;
  isEditformsData: any;
  isShowCard = false;
  DocumentName = [];
  intervalim=null;
  selectedPID: any;
  selectedBatchID: any;
  vendorRefresh: any;
  modalRefpushtoQC: any;
  ShowsaveButton: boolean;
  selectedmessageversion: any;
  selectedevice: any;
  selectedMessageFormat: any;
  selectedDeviceType: any;
  selectedConnectivityType: any;
  selectedBufferSize: any;
   selectedvbagent: any;
   selectedVBAgentId:string;
  selectedJobSubType: string;
  backupInterfacetype: any[];
  viewdeviceName: any;
  viewvbAgentName: any;
  viewMetaData:any;
  viewvdeviceType: any;
  viewdeviceType: any;
  viewdeviceIp: any;
  viewdevicePort: any;
  deviceInterface: boolean;
  selectedVBIPAddress: any;
  selectedSendReflexTestOrder: any;
  selectedReflexDeviceId: any;
  selectedReflexDeviceName: any;
  selectedCheckSumType: any;
  ModelInfo:any;
  selectedlabName() { }
  isLoadingroutes: boolean;
  notfounddata: boolean;
  isLoadingroutes1: boolean;
  notfounddata1: boolean;
  notfounddata2: boolean;
  FacilityNameList = <any>[];
  labNameList = <any>[];
  labNameImpList = <any>[];
  paylabNameList = <any>[];

  keywordsearch = "displayname";
  keywordsearch1 = "displayname1";
  filteredVendor: Observable<string[]>;
  filteredVendorImp: Observable<string[]>;
  filteredVendorExp: Observable<string[]>;
  filteredFacility: Observable<string[]>;
  filteredLabsImp: Observable<string[]>;
  filteredLabsExp: Observable<string[]>;

  isLinear = false;
  formid: any;
  filelab: any;
  data: AOA = [
    [1, 2],
    [3, 4],
  ];
  editdata: any;

  separatorKeysCodespayinglabs: number[] = [ENTER, COMMA];
  payingLabCtrl = new FormControl("");
  filteredpayingLabs: Observable<string[]>;
  allpayingLabs: string[] = ["Lab1", "Lab2", "Lab3", "Lab4", "Lab5"];
  @ViewChild("payingLabInput", { static: true }) payingLabInput: any;

  Ctypes: ConnectivityType[] = [
    { value: "API", viewValue: "API" },
    { value: "FTP", viewValue: "VA-FTP" },
    { value: "VPN", viewValue: "VPN" },
    { value: "External FTP", viewValue: "External FTP" },
    { value: "NONE", viewValue: "None" }

  ];
  allSelected = false;
  IType = [];
  interfaceTypes = [];
  LabFormGroup: FormGroup = this._formBuilder.group({
    lab: [this.Labs, Validators.required],
    payingLab: [this.payingLabs, Validators.required],
    interfaceBetween: ["", Validators.required],
    labContactdetails: this._formBuilder.array([]),
  });
  LabFormGroupImport: FormGroup = this._formBuilder.group({
    LabImport: [this.Labs, Validators.required],
    labContactdetailsImport: new FormArray([]),
  });
  LabFormGroupExport: FormGroup = this._formBuilder.group({
    LabExport: [this.Labs, Validators.required],
    labContactdetailsExport: new FormArray([]),
  });
  DeviceFormGroup: FormGroup = this._formBuilder.group({
    DeviceName: ["", Validators.required],
    JobSubType: ["", Validators.required],
    VBAgent: ["", Validators.required]
  });  
  FacilityFormGroup: FormGroup = this._formBuilder.group({
    facilityName: ["", Validators.required],
    facilityaddress: [""],
    facilityContactdetails: this._formBuilder.array([]),
  });
  requesterFormGroup: FormGroup = this._formBuilder.group({
    requesterName: ["", Validators.required],
    requesterEmail: ["", Validators.required],
    requesterPhone: [""],
    // action:"add"
  });
  requesterFormGroupImport: FormGroup = this._formBuilder.group({
    requesterImpName: ["", Validators.required],
    requesterImpEmail: ["", Validators.required],
    requesterImpPhone: [""],
  });
  requesterFormGroupExport: FormGroup = this._formBuilder.group({
    requesterExpName: ["", Validators.required],
    requesterExpEmail: ["", Validators.required],
    requesterExpPhone: [""],
  });
  projectFormGroupImport: FormGroup = this._formBuilder.group({
    projectImpName: ["", Validators.required],
    projectImpScope: [""],
  });
  projectFormGroupExport: FormGroup = this._formBuilder.group({
    projectExpName: ["", Validators.required],
    projectExpScope: ["", Validators.required],
  });
  VendorFormGroup: FormGroup = this._formBuilder.group({
    vendorName: [""],
    ApplicationName: [""],
    ApplicationVersion: [""],
    vendorContactdetails: this._formBuilder.array([]),
  });
  VendorFormGroupImport: FormGroup = this._formBuilder.group({
    vendorNameImp: [""],
    ApplicationNameImp: [""],
    ApplicationVersionImp: [""],
    vendorImpContactdetails: this._formBuilder.array([]),
  });

  VendorFormGroupExport: FormGroup = this._formBuilder.group({
    vendorNameExp: [""],
    ApplicationNameExp: [""],
    ApplicationVersionExp: [""],
    vendorExpContactdetails: this._formBuilder.array([]),
  });
  DetailsFormGroup: FormGroup = this._formBuilder.group({
    projName: [""],
    ProjInterfaceType: "",
    projScopeRequirement: [""],
    specialityCaseType: [""],
    specificDocs: [""],
    kickOffDate: [""],
    goLiveDate: [""],
    otherComments: [""],
    projDocuments: [""],
    metadata: [""]
  });

  ConnectivityFormGroup: FormGroup = this._formBuilder.group({

    ProjInterfacecard: this._formBuilder.array([]),

  });

  
  searchfacilityCleared(event) {
    this.isLoadingroutes = false;
    this.FacilityNameList = [];
    this.notfounddata = false;
  }

  ConnFormJson = {};
  issub: string
  viewform = []
  viewlabCont = []
  viewReqCont = []
  viewFacCont = []
  viewVenCont = []
  viewInterfaces = []
  Ftpdetails = []
  constructor(
    private _location: Location,
    public sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder,
    public ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private commonService: CommonService,
    private ngxuiloader: NgxUiLoaderService,
    private actr: ActivatedRoute,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private router: Router,
    private improute: ImportService,
    private http: HttpClient,
    private service :CommonService
  ) {
    this.projectNameGroup = this._formBuilder.group({
      projectName : "",
      type : "",
      vendorID : "",
      labGuid : ""
  })


    this.reactiveFormvendar = this._formBuilder.group({ namevendar: "" });
    this.filteredVendor = this.vendorNameControl.valueChanges.pipe(
      startWith(null),
      map((vendorName: string | null) =>
        vendorName ? this._filterVendor(vendorName) : this.VendorsList.slice()
      )
    );
    this.filteredLabs = this.labControl.valueChanges.pipe(
      startWith(null),
      map((lab: string | null) =>
        lab ? this._filterLab(lab) : this.allLabs.slice()
      )
    );
    this.filteredpayingLabs = this.payinglabControl.valueChanges.pipe(
      startWith(null),
      map((payinglab: string | null) =>
        payinglab
          ? this._filterpayingLab(payinglab)
          : this.allpayingLabs.slice()
      )
    );
    this.filteredFacility = this.facilityControl.valueChanges.pipe(
      startWith(null),
      map((facilityName: string | null) =>
        facilityName ? this._filterLab(facilityName) : this.allFacility.slice()
      )
    );
    this.filteredLabsImp = this.LabImportControl.valueChanges.pipe(
      startWith(null),
      map((labImport: string | null) =>
        labImport ? this._filterLabImp(labImport) : this.allLabsImp.slice()
      )
    );
    this.filteredLabsExp = this.LabExportControl.valueChanges.pipe(
      startWith(null),
      map((labExport: string | null) =>
        labExport ? this._filterLabExp(labExport) : this.allLabsExp.slice()
      )
    );
    this.filteredVendorImp = this.vendorNameImpControl.valueChanges.pipe(
      startWith(null),
      map((vendorNameImp: string | null) =>
        vendorNameImp ? this._filterVendorImp(vendorNameImp) : this.VendorsListImp.slice()
      )
    );
    this.filteredVendorExp = this.vendorNameExpControl.valueChanges.pipe(
      startWith(null),
      map((vendorNameExp: string | null) =>
        vendorNameExp ? this._filterVendorExp(vendorNameExp) : this.VendorsListExp.slice()
      )
    );
  }

  private _filterVendor(value: string): string[] {
    let exist
    // if(this.openvendorshow = false){
    const filterVendorValue = value.toLowerCase();
    if(this.VendorsList !== undefined && this.VendorsList.length>0){
      exist = this.VendorsList.filter((vendorName) =>
      vendorName.name.toLowerCase().includes(filterVendorValue)
    );
    }
    if (exist.length > 0) {
      this.openvendorshow = false;
      return exist;
    } else {
      this.openvendorshow = true;
      return [];
    }
  }

  private _filterVendorImp(value: string): string[] {
    let exist
    this.openvendorshowImp = false;
    // if(this.openvendorshowImp = false){
    const filterVendorValue = value.toLowerCase();
    if(this.VendorsListImp !== undefined &&this.VendorsListImp.length>0){
      exist = this.VendorsListImp.filter((vendorName) =>
      vendorName.name.toLowerCase().includes(filterVendorValue)
    );
    }

    if (exist.length > 0) {
      this.openvendorshowImp = false;
      return exist;
    } else {
      this.openvendorshowImp = true;
      return [];
    }

  }

  private _filterVendorExp(value: string): string[] {
    let exist

    // if(this.openvendorshowExp = false){
    const filterVendorValue = value.toLowerCase();
    if(this.VendorsListExp !== undefined &&this.VendorsListExp.length>0){
      exist= this.VendorsListExp.filter((vendorName) =>
      vendorName.name.toLowerCase().includes(filterVendorValue)
    );
    }
    if (exist.length > 0) {
      this.openvendorshowExp = false;
      return exist;
    } else {
      this.openvendorshowExp = true;
      return [];
    }
  }

  private _filterLabImp(value: string): string[] {
    const filterlabImpValue = value.toLowerCase();
    return this.allLabsImp.filter((labImp) =>
      labImp.toLowerCase().includes(filterlabImpValue)
    );
  }
  private _filterLabExp(value: string): string[] {
    const filterlabExpValue = value.toLowerCase();
    return this.allLabsExp.filter((labExp) =>
      labExp.toLowerCase().includes(filterlabExpValue)
    );
  }

  openvendorModalpopup(event) {
    if (this.openvendorshow == true) {
      this.openVendorPopup();
    } else {
      if(this.VendorsList!==undefined &&this.VendorsList.length>0)
      {
      this.selectInttypes(event.option.value);
      var vid = this.VendorsList.find((va) => va.name == event.option.value);
      this.venMnemonic = vid.mnemonic
      this.vendoName = vid.name
      this.vendoID = vid.Id
      }
    }
  }
   VendorKeyClearFunc(event: any) {
     if(event.target.value ==''){

      this.interfaceTypes=[];
      this.vendoID= undefined;
     }
   }


  openvendorImpModalpopup(event) {
    if (this.openvendorshowImp) {
      this.openVendorImportPopup();
    } else {
      var vid = this.VendorsListImp.find((va) => va.name == event.option.value);
      this.venMnemonicImp = vid.mnemonic
      this.vendoNameImp = vid.name
      this.vendoIDImp = vid.Id
    }
  }

  openvendorExpModalpopup(event) {

    if (this.openvendorshowExp) {
      this.openVendorExportPopup();
    } else {
      var vid = this.VendorsListExp.find((va) => va.name == event.option.value);
      this.venMnemonicExp = vid.mnemonic
      this.vendoNameExp = vid.name
      this.vendoIDExp = vid.Id
    }
  }

  getNewConnCard(index) {

    let values = this.ConnectivityFormGroup.value.ProjInterfacecard
   let uniq = values.filter(va => va.interfaceType == index)
   if(uniq.length == 0 || uniq === undefined) {
    let forcon = this._formBuilder.group({
      interfaceType:index,
      //ContentType: "",
      ContentType:  this.isdevice?[this.ContType[0].lookupid]:"",
      ConnectivityType: this.isdevice?"Device":"",
      Description: "",
      vpnIp: "",
      vpnHost: "",
      vpnPort: "",
      externalFtpUsername: "",
      externalFtpPassowrd: "",
      externalFtpHost: "",
      externalFtpFolderPath: "", //For Inbound
      externalFtpRemotePath: "",   // For Outbound
      externalFtpArchivePath: "",
      externalFtpPort: "",
      DeviceIp:this.selectedVBIPAddress?this.selectedVBIPAddress:"",
      DevicePort:"",
      DeviceAgentid:"",
      DeviceName:"",
      JobSubType:"",
    });
    this.onAddNewCard().push(forcon);
  }
    // this.onAddNewCard[index] = forcon
    // this.onAddNewCard[index].InterfaceName = name
  }

  onAddNewCard(): FormArray {
    return this.ConnectivityFormGroup.get("ProjInterfacecard") as FormArray;
  }

  removeConnCard(i) {
    this.onAddNewCard().removeAt(i);
  }

  handleFileInput(evt: any) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = evt.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, {
        type: "binary",
        cellText: false,
        cellDates: true,
      });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet, {
          dateNF: "yyyy-mm-dd",
        });
        return initial;
      }, {});

      //////////
      this.isjsonData = jsonData.Sheet1;
    };
    setTimeout(() => {
      this.isjsonData;
      this.commonService.insertInterfacefromFile(this.isjsonData).subscribe(
        (data) => {
          if (data !== null) {
            this.ngxuiloader.stop();
            this.toastr.success("Uploaded Successfully", "Success", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
            this.getFormListdata(0);
          } else {
            this.ngxuiloader.stop();
            this.toastr.error("Failed to Upload", "Failed", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
          }
        },
        (error) => {
          this.ngxuiloader.stop();
          this.toastr.error("Failed to Upload", "Failed", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
      );
    });
    reader.readAsBinaryString(file);
    this.myFileInput.nativeElement.value = "";
  }

   async patchformImpExpValue(value: any,isimport:string) {
    let isValue = JSON.parse(value);
    if(isimport=='import'){
      this.projImpID = isValue.projectID;
      this.ImpID = isValue.id
      this.vendoIDImp=isValue.VendorId
     await this.labImportPatch(value);
     await this.reqImportPatch(value);
     await this.projImportPatch(value);
     await this.venImportPatch(value);



    }else{
      let isValue = JSON.parse(value);
      this.vendoIDExp=isValue.VendorId
      this.projExpID = isValue.projectID;
      this.ExpID = isValue.id
      this.labExportPatch(value);
      this.reqExportPatch(value);
      this.projExportPatch(value);
      this.venExportPatch(value);
    }

  }

  labExportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.LabExportControl.patchValue(isValue.sourceName);
    this.isEditLabExpGUID = isValue.sourceGuid
    this.ExpDeploymentKey =isValue.sourceDeploymentKey
    this.LabFormGroupExport.patchValue({
      LabExport: this.LabExportControl
    });
    if (isValue.contactDetails !== "") {
      let contExp = JSON.parse(isValue.contactDetails);
      let Expobj = contExp.filter((x) => x.ContactType === "lab")
      if (Expobj.length > 0) {
        for (let i = 0; i < Expobj.length; i++) {
          this.labExpContID = Expobj[i].contactid
          let expGroup = this._formBuilder.group({
            name: Expobj[i].ContactName,
            role: Expobj[i].role,
            phone: Expobj[i].phonenumber,
            email: Expobj[i].emailid,
            id: this.labExpContID !== undefined ? this.labExpContID : this.labExpId,
            action: "",
          });
          this.addlabcontactExportfield().push(expGroup)
        }
        this.LabFormGroupExport.patchValue({
          labContactdetailsExport: this.addlabcontactExportfield().value
        })
      }
    }
  }

  reqExportPatch(value: any) {
    let isValue = JSON.parse(value);
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let reqobj = contlab.filter((x) => x.ContactType === "requester");
      if (reqobj.length > 0) {
        this.reqExpId = reqobj[0].contactid
        this.requesterFormGroupExport.patchValue({
          requesterExpName: reqobj[0].ContactName,
          requesterExpEmail: reqobj[0].emailid,
          requesterExpPhone: reqobj[0].phonenumber,
          id: this.reqExpId
        });
      }
    }
  }

  projExportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.projectFormGroupExport.patchValue({
      projectExpName: isValue.projectName,
      projectExpScope: isValue.projectScope
    })
  }

  venExportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.vendorNameExpControl.patchValue(isValue.vendorName);
    this.VendorFormGroupExport.patchValue({
      vendorNameExp: this.vendorNameExpControl.value,
      ApplicationNameExp: isValue.appName,
      ApplicationVersionExp: isValue.appVersion,
    });
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let venobjExp = contlab.filter((x) => x.ContactType === "vendor");
      if (venobjExp.length > 0) {
        for (let m = 0; m < venobjExp.length; m++) {
          this.venContExpId = venobjExp[m].contactid
          let vengroup = this._formBuilder.group({
            name: venobjExp[m].ContactName,
            role: venobjExp[m].role,
            phone: venobjExp[m].phonenumber,
            email: venobjExp[m].emailid,
            id: this.venContExpId !== undefined ? this.venContExpId : this.venExpId,
            action: "",
          });
          this.addExpVendorContField().push(vengroup);
          this.VendorFormGroupExport.patchValue({
            vendorExpContactdetails: this.addExpVendorContField().value,
          });
        }
      }
    }
  }


async  labImportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.LabImportControl.patchValue(isValue.sourceName);
    this.isEditLabImpGUID = isValue.sourceGuid
    this.ImpDeploymentKey=isValue.sourceDeploymentKey;
    this.LabFormGroupImport.patchValue({
      LabImport: this.LabImportControl.value
    });
    if (isValue.contactDetails !== "") {
      let contImp = JSON.parse(isValue.contactDetails);
      let Impobj = contImp.filter((x) => x.ContactType === "lab")
      if (Impobj.length > 0) {
        for (let i = 0; i < Impobj.length; i++) {
          this.labimpContID = Impobj[i].contactid
          let impGroup = this._formBuilder.group({
            name: Impobj[i].ContactName,
            role: Impobj[i].role,
            phone: Impobj[i].phonenumber,
            email: Impobj[i].emailid,
            id: this.labimpContID !== undefined ? this.labimpContID : this.labimpId,
            action: "",
          });
          this.addlabcontactImportfield().push(impGroup)
        }
        this.LabFormGroupImport.patchValue({
          labContactdetailsImport: this.addlabcontactImportfield().value
        })
      }
    }
  }

  async  reqImportPatch(value: any) {
    let isValue = JSON.parse(value);
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let reqobj = contlab.filter((x) => x.ContactType === "requester");
      if (reqobj.length > 0) {
        this.reqImpId = reqobj[0].contactid
        this.requesterFormGroupImport.patchValue({
          requesterImpName: reqobj[0].ContactName,
          requesterImpEmail: reqobj[0].emailid,
          requesterImpPhone: reqobj[0].phonenumber,
          id: this.reqImpId
        });
      }
    }
  }

async  projImportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.projectFormGroupImport.patchValue({
      projectImpName: isValue.projectName,
      projectImpScope: isValue.projectScope
    })
  }

  async  venImportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.vendorNameImpControl.patchValue(isValue.vendorName);
    this.VendorFormGroupImport.patchValue({
      vendorNameImp: this.vendorNameImpControl.value,
      ApplicationNameImp: isValue.appName,
      ApplicationVersionImp: isValue.appVersion,
    });
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let venobjImp = contlab.filter((x) => x.ContactType === "vendor");
      if (venobjImp.length > 0) {
        for (let m = 0; m < venobjImp.length; m++) {
          this.venContId = venobjImp[m].contactid
          let vengroup = this._formBuilder.group({
            name: venobjImp[m].ContactName,
            role: venobjImp[m].role,
            phone: venobjImp[m].phonenumber,
            email: venobjImp[m].emailid,
            id: this.venContId !== undefined ? this.venContId : this.vencontimpId,
            action: "",
          });
          this.addImpVendorContField().push(vengroup);
          this.VendorFormGroupImport.patchValue({
            vendorImpContactdetails: this.addImpVendorContField().value,
          });
        }
      }
    }
  }


  labIntFormPatch(value: any) {
    let isValue = JSON.parse(value);
    this.labControl.patchValue(isValue.sourceName);
    this.payinglabControl.patchValue(isValue.payingLabName);
    this.isEditLabGUID = isValue.sourceGuid
    this.isEditPayingGUID = isValue.payingLabGuid
    this.LabFormGroup.patchValue({
      lab: this.labControl,
      payingLab: this.payinglabControl,
      interfaceBetween: isValue.interfaceBetween,
    });   
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let labobj = contlab.filter((x) => x.ContactType === "lab");

      if (labobj.length > 0) {
        for (let i = 0; i < labobj.length; i++) {
          let labgroup = this._formBuilder.group({
            name: labobj[i].ContactName,
            role: labobj[i].role,
            phone: labobj[i].phonenumber,
            email: labobj[i].emailid,
            id: labobj[i].contactid,
            action: "",
          });
          this.addlabcontactfield().push(labgroup);
          // this.addlabcontactfield().at(i).patchValue(labobj)
          this.LabFormGroup.patchValue({
            labContactDetials: this.addlabcontactfield().value,
          });
        }
      }
    }
  }

  facIntFormPatch(value: any) {

    let isValue = JSON.parse(value);
    this.facilityControl.patchValue(isValue.destinationName);
    this.facilityAddressControl.patchValue(isValue.destinationAddress1);
    this.isEditFaciltyGUID = isValue.destinationGUID;
    this.FacilityFormGroup.patchValue({
      facilityName: this.facilityControl.value,
      facilityaddress: this.facilityAddressControl.value,
    });

    this.facaddr1 = this.facilityAddressControl.value;

    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let facobj = contlab.filter((x) => x.ContactType === "facility");
      if (facobj.length > 0) {
        for (let n = 0; n < facobj.length; n++) {
          let facgroup = this._formBuilder.group({
            name: facobj[n].ContactName,
            role: facobj[n].role,
            phone: facobj[n].phonenumber,
            email: facobj[n].emailid,
            id: facobj[n].contactid,
            action: "",
          });
          this.addfacilitycontactfield().push(facgroup);
          this.FacilityFormGroup.patchValue({
            facilityContactDetails: this.addfacilitycontactfield().value,
          });
        }
      }
    }
  }

  venIntFormPatch(value: any) {
    let isValue = JSON.parse(value);
    this.vendorNameControl.patchValue(isValue.vendorName);
    this.VendorFormGroup.patchValue({
      vendorName: this.vendorNameControl.value,
      ApplicationName: isValue.appName,
      ApplicationVersion: isValue.appVersion,
    });
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let venobj = contlab.filter((x) => x.ContactType === "vendor");
      if (venobj.length > 0) {
        for (let m = 0; m < venobj.length; m++) {
          let vengroup = this._formBuilder.group({
            name: venobj[m].ContactName,
            role: venobj[m].role,
            phone: venobj[m].phonenumber,
            email: venobj[m].emailid,
            id: venobj[m].contactid,
            action: "",
          });
          this.addvendorcontactfield().push(vengroup);
          this.VendorFormGroup.patchValue({
            vendorContactdetails: this.addvendorcontactfield().value,
          });
        }
      }
    }
  }

  reqID: any
  reqNoID: number
  reqIntFormPatch(value: any) {
    let isValue = JSON.parse(value);
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let reqobj = contlab.filter((x) => x.ContactType === "requester");
      if (reqobj.length > 0) {
        this.reqID = reqobj[0].contactid
        this.requesterFormGroup.patchValue({
          requesterName: reqobj[0].ContactName,
          requesterEmail: reqobj[0].emailid,
          requesterPhone: reqobj[0].phonenumber,
          id: this.reqID
        });
      }
    }
  }

  patchIntType = [];
  projfileid: number
  detailIntFormPatch(value: any) {

    let isValue = JSON.parse(value);
    let arraytype = [];

    if (isValue.interfaces !== "") {
      let connectivityEdit = JSON.parse(isValue.interfaces);
      for (let i = 0; i < connectivityEdit.length; i++) {
        arraytype.push(connectivityEdit[i].interfaceType);
      }
    }
    this.patchIntType = arraytype;
    this.projFiles = isValue.projectDocs
    if (this.projFiles !== null) {

      for (let j = 0; j < this.projFiles.length; j++) {
        this.projfileid = this.projFiles[j].id
        this.fileid = this.projFiles[j].fileid
      }
    }
    else {
      this.projFiles = [];
    }
    
    this.DetailsFormGroup.patchValue({
      projName: isValue.projectName,
      projScopeRequirement: isValue.projectScope,
      specialityCaseType: isValue.specialityCaseType,
      specificDocs: '',
      kickOffDate: isValue.kickOffDate,
      goLiveDate: isValue.goLiveDate,
      otherComments: isValue.otherComments,
      ProjInterfaceType: this.patchIntType,
      projDocuments: '',
      metadata: JSON.stringify(JSON.parse(isValue.interfaces)[0].connectivityDetails.metadata)
    });
  }

  connIntFormPatch(value: any) {


    let isValue = JSON.parse(value);
   this.getsourceInfo(isValue);
   this.getTargetInfo(isValue);

    let arraytype = []
    if (isValue.interfaces !== "") {
      let connectivityEdit = JSON.parse(isValue.interfaces);

      this.onAddNewCard().clear();
      for (let i = 0; i < connectivityEdit.length; i++) {
        arraytype.push(connectivityEdit[i].interfaceType);
        this.ConnectivityFormGroup.patchValue({
          ConnectivityType: connectivityEdit[i].ConnectivityType,
          Description: connectivityEdit[i].description,
          // ContentTypeInbound: connectivityEdit[i].contentType,
          // ContentTypeOutbound: connectivityEdit[i].contentType,
        });
        // if(this.ConnectivityFormGroup.value.ProjInterfacecard !== undefined){
        let forsgroup = this._formBuilder.group({
          interfaceType:connectivityEdit[i].interfaceType,
          ContentType: connectivityEdit[i].contentType,
          ConnectivityType: connectivityEdit[i].ConnectivityType,
          Description: connectivityEdit[i].description,
          DeviceIp: connectivityEdit[i].connectivityDetails.deviceIp,
          vpnIp: connectivityEdit[i].connectivityDetails.vpnIp,
          vpnHost: connectivityEdit[i].connectivityDetails.vpnHost,
          vpnPort: connectivityEdit[i].connectivityDetails.vpnPort,
          externalFtpUsername: connectivityEdit[i].connectivityDetails.externalFtpUsername,
          externalFtpPassowrd: connectivityEdit[i].connectivityDetails.externalFtpPassowrd,
          externalFtpHost: connectivityEdit[i].connectivityDetails.externalFtpHost,
          externalFtpFolderPath: connectivityEdit[i].connectivityDetails.externalFtpFolderPath,
          externalFtpRemotePath: connectivityEdit[i].connectivityDetails.externalFtpRemotePath,
          externalFtpArchivePath: connectivityEdit[i].connectivityDetails.externalFtpArchivePath,
          externalFtpPort: connectivityEdit[i].connectivityDetails.externalFtpPort,
          DevicePort:connectivityEdit[i].connectivityDetails.devicePort,
        });
        this.onAddNewCard().push(forsgroup);

        this.onAddNewCard().at(i).patchValue(connectivityEdit[i]);
this.ConnectivityFormGroup.patchValue({
        ProjInterfacecard: this.onAddNewCard().value,
      });
      // this.ConnectivityFormGroup.value.ProjInterfacecard = connectivityEdit[i]
        // }
      }
    }
   }


  patchformvalue(value: any) {
    let isValue = JSON.parse(value);
    this.projvaid = isValue.projectID;
    this.projid = isValue.id;
    this.labIntFormPatch(value);
    this.reqIntFormPatch(value);
    this.venIntFormPatch(value);
    this.facIntFormPatch(value);
    this.detailIntFormPatch(value);
    this.connIntFormPatch(value);
    if(isValue.interfaceBetween === "Between Lab and Device"){
      this.deviceFormPatch(value);
    }
    
  }


  async deviceFormPatch(value: any) {
    let isValue = JSON.parse(value);

    if (isValue.interfaces !== "[]") {

        let interfacedetail = JSON.parse(isValue.interfaces);
        let objinterface = interfacedetail[0].connectivityDetails;

        if (objinterface) {
            this.selectedlabobj.orgguid = objinterface.sourceorgguid ? objinterface.sourceorgguid : '';
            this.selectedlabobj.deploymentkey = objinterface.sourceDeploymentKey ? objinterface.sourceDeploymentKey : '';

            
                this.isdevice = true;
                this.flag = false;
                    let dl = await this.getDevicelist();
                    this.selectInttype();
                    let aglst = await this.Getvbagentsdetails();
                this.Ctypes.push({ value: "Device", viewValue: "Device" });

                const filteredVendorArray = this.VendorsList.filter(vendor => vendor.name === 'Device');
                this.filteredVendor = of(filteredVendorArray); 

                if (!isValue.vendorName) {
                    this.getVendorname();
                }

            this.DeviceFormGroup.patchValue({
                DeviceName: objinterface.deviceName || '', 
                JobSubType: objinterface.deviceSubType || '', 
                VBAgent: objinterface.vbagentName || ''  
            });

            // // Conditionally add to DeviceList
            this.DeviceList = [{
                DeviceName: objinterface.deviceName || '',  // Assign only if present
                MessageVersion: objinterface.deviceMessageVersion || '', // Assign only if present
                MessageFormat: objinterface.deviceMessageFormat || '', // Assign only if present
                DeviceType: objinterface.deviceType || '', // Assign only if present
                ConnectivityType: objinterface.deviceConnectivityType || '', // Assign only if present
                BufferSize: objinterface.deviceBufferSize || '', // Assign only if present
                SendReflexTestOrder: objinterface.SendReflexTestOrder || '',
                ReflexDeviceId: objinterface.ReflexDeviceId || '',
                ReflexDeviceName: objinterface.ReflexDeviceName || '',
                CheckSumType: objinterface.CheckSumType || '',
                ModelInfo: objinterface.ModelInfo || ''
            }];

            // Conditionally add to vbagentlist
            this.vbagentlist = [{
                AgentName: objinterface.vbagentName || '', // Assign only if present
                AgentID: objinterface.deviceAgentId || '' ,
                IPAddress: objinterface.deviceIp || ''  // Assign only if present
            }];

            if(objinterface.deviceIp){
              this.selectedVBIPAddress=objinterface.deviceIp
            }

            // Conditionally add to jobsubtypelist
            this.jobsubtypelist = [{
                lookupvalue: objinterface.deviceType || '', // Assign only if present
                lookupid: objinterface.deviceType || '' // Assign only if present
            }];

            if (isValue.vendorName) {
                // Continue further actions like selectInttypes
                //this.selectInttypes(isValue.vendorName);

                
                  var vid = this.VendorsList.find((va) => va.name == isValue.vendorName);
                  this.vendoID = vid.Id;
                  this.commonService.GetInterFaces(this.vendoID).subscribe((data) => {
                    if (data) {
                      this.interfaceTypes = data;
                      this.backupInterfacetype=data;

                      if(this.interfaceTypes){
                        //console.log("Interfacetype : "+this.interfaceTypes);
                        const currentValue = this.DetailsFormGroup.get('ProjInterfaceType').value;
                        if (!currentValue) {
                        this.DetailsFormGroup.patchValue({
                          ProjInterfaceType: this.interfaceTypes
                      });
                    }
                      }
      
                      if (objinterface.deviceSubType) {
                        this.onJobSubTypeSelected(objinterface.deviceSubType);                
                    }
                    }
                  })                

                
                
            }

           

            // Conditionally add to VendorsList
            if (isValue.vendorName) {
                this.VendorsList = [{
                    name: isValue.vendorName || '', // Assign only if present
                    Id: isValue.VendorId || '' // Assign only if present
                }];
            }
        }

    } 
}



  getdirection(value: any) {
    this.isOutbound = false;
    // let dir = this.ConnectivityFormGroup.get("InterfaceType").value;
    let dir = this.DetailsFormGroup.get("ProjInterfaceType").value;
    if (value == "In" && dir != null) {
      for (let i = 0; i < dir.length; i++) {
        if (dir[i].includes("In")) {
          this.isInbound = true;
          return true;
        }
      }
    }
    if (value == "Out" && dir != null) {
      for (let i = 0; i < dir.length; i++) {
        if (dir[i].includes("Out")) {
          this.isOutbound = true;
          return true;
        }
      }
    }
  }

  //////////////////////adding and removing contacts
  //////lab
  createlabcontactImport() {
    let labimpg = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: this.labimpContID !== undefined ? this.labimpContID : 0,
      action: "active"
      // contactType: ''
    });
    this.addlabcontactImportfield().push(labimpg);
  }

  createlabcontactExport() {
    let labexpg = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active"
      // contactType: ''
    });
    this.addlabcontactExportfield().push(labexpg)
  }

  createlabcontact() {
    let labg = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active",
      // contactType: ''
    });
    this.addlabcontactfield().push(labg);
  }
  removelabcontact(i: number) {
   let arry = this.LabFormGroup.get("labContactdetails") as FormArray;
    arry.controls[i].patchValue({
      action: "passive",
    });

    // arry.removeAt(i);
  }

  addlabcontactfield(): FormArray {
    return this.LabFormGroup.get("labContactdetails") as FormArray;
  }

  addlabcontactImportfield(): FormArray {
    return this.LabFormGroupImport.get("labContactdetailsImport") as FormArray;
  }
  removelabcontactImport(i: number) {
    let arry = this.LabFormGroupImport.get("labContactdetailsImport") as FormArray;
    arry.controls[i].patchValue({
      action: "passive",
    });
  }
  addlabcontactExportfield(): FormArray {
    return this.LabFormGroupExport.get(
      "labContactdetailsExport"
    ) as FormArray;
  }
  removelabcontactExport(i: number) {
    let arry = this.LabFormGroupExport.get(
      "labContactdetailsExport"
    ) as FormArray;
    arry.controls[i].patchValue({
      action: "passive",
    });
  }
  /////////////facility
  createfacilitycontact() {
    let facg = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active",
      // contactType: ''
    });
    this.addfacilitycontactfield().push(facg);
  }
  removefacilitycontact(i: number) {
    let arry = this.FacilityFormGroup.get(
      "facilityContactdetails"
    ) as FormArray;
    // arry[i].action ="delete"
    arry.controls[i].patchValue({
      action: "passive",
    });
  }
  addfacilitycontactfield(): FormArray {
    return this.FacilityFormGroup.get("facilityContactdetails") as FormArray;
  }

  /////////////////vendor
  createvendorcontact() {
    let veng = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active",
      // contactType: ''
    });
    this.addvendorcontactfield().push(veng);
  }
  removevendorcontact(i: number) {
    let arry = this.VendorFormGroup.get("vendorContactdetails") as FormArray;
    // arry[i].action ="delete"
    arry.controls[i].patchValue({
      action: "passive",
    });
  }


  addvendorcontactfield(): FormArray {
    return this.VendorFormGroup.get("vendorContactdetails") as FormArray;
  }

  ///////////////////////////////////////////////
  createvendorImpcontact() {
    let veng = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active",
      // contactType: ''
    });
    this.addImpVendorContField().push(veng);
  }
  removeImpVendorCont(i: number) {
    let arry = this.VendorFormGroupImport.get("vendorImpContactdetails") as FormArray;
    // arry[i].action ="delete"
    arry.controls[i].patchValue({
      action: "passive",
    });
  }
  addImpVendorContField(): FormArray {
    return this.VendorFormGroupImport.get("vendorImpContactdetails") as FormArray;
  }

  /////////////////////////////////
  createvendorExpcontact() {
    let veng = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active",
      // contactType: ''
    });
    this.addExpVendorContField().push(veng);
  }
  removeExpVendorCont(i: number) {
    let arry = this.VendorFormGroupExport.get("vendorExpContactdetails") as FormArray;
    // arry[i].action ="delete"
    arry.controls[i].patchValue({
      action: "passive",
    });
  }
  addExpVendorContField(): FormArray {
    return this.VendorFormGroupExport.get("vendorExpContactdetails") as FormArray;
  }

  async selectedVendorEvent(event) {
    this.vendarnamevalue = event.name;
    this.vendorid = event.Id;
    // select connectivity based on the vendor mapping
    let vendorObj = {
      name: this.vendarnamevalue,
    };
    this.ngxuiloader.start();

    await this.commonService
      .postapi("api/Routes/GetLastRouteConnectivityByVendor", vendorObj)
      .then(
        (Response) => {
          if (Response) {
            if (
              (Response["status"] && Response["statusText"]) ||
              Response["statusText"] === "Unknown Error"
            ) {
              this.ngxuiloader.stop();
              this.toastr.error("Something Went Wrong!", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
              });
            } else {
              this.ngxuiloader.stop();
              let response = Response as any;
              if (response["status"] == "success") {
                if (
                  this.DetailsFormGroup.value.ProjInterfaceType ==
                  "EllkayInbound" ||
                  this.DetailsFormGroup.value.ProjInterfaceType == "Inbound"
                ) {
                  this.modelradio.option = response["responsebody"];
                }
                // if (this.interfacetype == "EllkayResultOutbound" || this.interfacetype == "Outbound") {
                //   this.modelradio.option =JSON.parse(vendordata[0].connectivity).connectivitytype.split(",")[0];
                // }
              }
            }
          } else {
            this.ngxuiloader.stop();
          }
        },
        (error) => {
          this.ngxuiloader.stop();
          this.toastr.error("Something Went Wrong!", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      );
  }

  async getVendorsName(event) {
    var vendarvalue = event;
    this.vendarnamevalue = "";
    if (vendarvalue != "") {
      this.isLoadingVendorName = false;
      if (this.VendorsList.length > 0) {
        this.filterVendorList(vendarvalue);
      } else {
        this.VendorsList = this.VendornameBackuplist;
        this.VendorsListExp = JSON.parse(JSON.stringify( this.VendornameBackuplist));
        this.filterVendorList(vendarvalue);
      }
    }
  }

  getVendorname() {
    this.ngxuiloader.start();

    var url = "api/Vendor/GetVendorName";
    this.commonService.getapiurl(url).subscribe((data) => {
      if (data) {
        this.VendorsList = data as any[];
        this.VendorsList = this.commonService.transformAlphabetically(
          this.VendorsList,
          "name"
        );
        this.VendorsListImp = JSON.parse(JSON.stringify(this.VendorsList))
        this.VendorsListExp = JSON.parse(JSON.stringify(this.VendorsList))
        this.VendornameBackuplist = this.VendorsList;
        this.ngxuiloader.stop();
        this.reactiveFormvendar.setValue({ namevendar: "" });
      } else {
        this.ngxuiloader.stop();
      }
    });
  }



  filterVendorList(event) {
    this.VendorsList = this.VendorsList.filter((t) =>
      t.name.toLowerCase().includes(event.toLowerCase())
    );
    if (this.VendorsList.length == 0) {
      this.VendorsList = [];
      this.notfounddata3 = true;
      this.isLoadingVendorName = false;
    } else {
      // this.VendorsList=[]
      this.notfounddata3 = false;
      this.isLoadingVendorName = true;
      // this.VendorsList = event
    }
    this.isLoadingVendorName = false;
  }

  filterVendorListImp(event) {
    this.VendorsListImp = this.VendorsListImp.filter((t) =>
      t.name.toLowerCase().includes(event.toLowerCase())
    );
    if (this.VendorsListImp.length == 0) {
      this.VendorsListImp = [];
      this.notfounddataVenImp = true;
      this.isLoadingVendorNameImp = false;
    } else {
      // this.VendorsList=[]
      this.notfounddataVenImp = false;
      this.isLoadingVendorNameImp = true;
      // this.VendorsList = event
    }
    this.isLoadingVendorNameImp = false;
  }


  searchVendarNameCleared(event) {
    this.isLoadingVendorName = false;
    this.vendarnamevalue = "";
    this.vendorid = "";
    this.VendorsList = this.VendornameBackuplist;
    this.VendorFormGroup.setValue({ vendorName: "" });
  }

  searchVendarNameClearedImp(event) {
    this.isLoadingVendorNameImp = false;
    this.vendarnamevalueImp = "";
    this.vendoridImp = "";
    this.VendorsListImp = this.VendornameBackuplistImp;
    this.VendorFormGroupImport.setValue({ vendorNameImp: "" });
  }


  openVendorPopup() {
    let obj = {
      data: "",
      vendornamelist: this.VendornameBackuplist,
      component: "request-form",
      Type: "Add",
      vendorname: this.VendorFormGroup.get("vendorName").value,
    };
    const modalRef = this.modalService.open(SavevendorComponent, {
      windowClass: "saveVendormodal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.inputDataInModalComponent = obj;
    modalRef.result.then((data) => {
      if (data.dataflag) {
        this.getVendorname();
        this.reactiveFormvendar.patchValue({
          namevendar: data.vendorname,
        });
        this.vendarnamevalue = data.vendorname;
      }
    });
  }

  openVendorImportPopup() {
    let obj = {
      data: "",
      vendornamelist: this.VendornameBackuplistImp,
      component: "request-form",
      Type: "Add",
      vendorname: this.VendorFormGroupImport.get("vendorNameImp").value,
    };
    const modalRef = this.modalService.open(SavevendorComponent, {
      windowClass: "saveVendormodal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.inputDataInModalComponent = obj;
    modalRef.result.then((data) => {
      if (data.dataflag) {
        this.getVendorname();
        this.reactiveFormvendarImp.patchValue({
          namevendar: data.vendorname,
        });
        this.vendarnamevalueImp = data.vendorname;
      }
    });
  }

  openVendorExportPopup() {
    let obj = {
      data: "",
      vendornamelist: this.VendornameBackuplistExp,
      component: "request-form",
      Type: "Add",
      vendorname: this.VendorFormGroupExport.get("vendorNameExp").value,
    };
    const modalRef = this.modalService.open(SavevendorComponent, {
      windowClass: "saveVendormodal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.inputDataInModalComponent = obj;
    modalRef.result.then((data) => {
      if (data.dataflag) {
        this.getVendorname();
        this.reactiveFormvendarExp.patchValue({
          namevendar: data.vendorname,
        });
        this.vendarnamevalueExp = data.vendorname;
      }
    });
  }

  entitytable(obj) {
    let final = {
      ...obj,
      modifierid: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
        .userdata["userid"],
    };
    this.commonService.EntityInsertion(final).subscribe(
      (data) => {
        this.ngxuiloader.stop();
        var result = data;
      },
      (error) => {
        // this.autocompLoading = false;
        console.error();
      }
    );
  }



  onFileSelected(event) {
    let list = [];
    this.ngxuiloader.start();
    list = event.target.files;
    let f;
    for (let i = 0; i < list.length; i++) {
      f = list[i];
      this.DocumentName.push(f.name);
      var reader = new FileReader();
      reader.onload = (function (theFile) {
        return function (e) {
          var binaryData = e.target.result;
          var base64String = window.btoa(binaryData.toString());
          fobj.fileContent = base64String;
        };
      })(f);
      reader.readAsBinaryString(f);
      let fobj = {
        fileContent: "",
        filename: list[i].name,
        size: list[i].size,
      };
      // setTimeout(() => {
      if (fobj !== undefined) {
        this.projFiles.push(fobj);
        var pf = this.projFiles.find((va) => va.name == event.value);
        this.filecontent = pf.fileContent;
        this.fileName = pf.filename;
      }
      // });
    }
    this.ngxuiloader.stop();
  }


  fileSelectedDelete(i) {
    this.removeFile(i)
    if (this.deleteFile == "passive") {
      this.toastr.warning("File will be deleted on Saving the Request Form.", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }

  removeFile(i) {

    let file = this.projFiles.find(v=> v.id == this.projFiles[i].id)
    file.action = "passive"
    this.deleteFile = file.action

    // this.projFiles.splice(i, 1);
    // this.DocumentName.splice(i, 1);
  }

  selectInttypes(event: any) {
    var vid = this.VendorsList.find((va) => va.name == event);
    this.vendoID = vid.Id;
    this.commonService.GetInterFaces(this.vendoID).subscribe((data) => {
      if (data) {
        this.interfaceTypes = data;
        this.backupInterfacetype=data;
      }
    })
  }
  selectInttype() {
    if(this.isdevice){
      this.commonService.getIntContType('DeviceJobSubType').subscribe((data) => {
        if (data) {
          this.jobsubtypelist = data
          }
      })
    }
    else{
      this.commonService.getIntContType('interfacetype').subscribe((data) => {
        if (data) {
          this.IType = data
        }
      })
      this.commonService.getIntContType('interfacecontenttype').subscribe((data) => {
        if (data) {
          this.ContType = data
        }
      })
    }
  }
checkJson(){
  this.isValidJson = false;
  try {
    // Try to parse the string as JSON
    JSON.parse(this.DetailsFormGroup.value.metadata);
    if(typeof(JSON.parse(this.DetailsFormGroup.value.metadata)) === 'object')
    this.isValidJson =  true; // If parsing succeeds, it's a valid JSON
  } catch (e) {
    this.isValidJson = false; // If an error occurs, it's not valid JSON
  }
}


  submitform(id: any, isSubmitForms: boolean) {

      let serializedMetadata = this.DetailsFormGroup.value.metadata;

this.oobj=[]
    let pguid = "";
    if (this.selectedlabobj !== undefined) {
      this.LabFormGroup.patchValue({ lab: this.selectedlabobj.Displayname });
    }
    if (this.selectedpaylabobj !== undefined) {
      this.LabFormGroup.patchValue({
        payingLab: this.selectedpaylabobj.Displayname,
      });
    }
    if(this.isdevice){
      this.FacilityFormGroup.patchValue({
        facilityName: this.selectedlabobj.Displayname,
        facilityaddress: this.selectedlabobj.Address1 !== '' || this.selectedlabobj.Address1 !== null ? this.selectedlabobj.Address1 : this.selectedlabobj.Address2
      });
    }
    else if (this.selectedfacilityobj !== undefined) {
      this.FacilityFormGroup.patchValue({
        facilityName: this.selectedfacilityobj.Displayname,
      });
    }

this.ConnectivityFormGroup.value.ProjInterfacecard
    // let dir = this.ConnectivityFormGroup.get("InterfaceType").value;
    let dir = this.DetailsFormGroup.get("ProjInterfaceType").value;
    let isNotEmpty = false;
    if (this.ConnectivityFormGroup.value.ProjInterfacecard.length > 0) {
      isNotEmpty = this.ConnectivityFormGroup.value.ProjInterfacecard.some(item => {
        return (item.interfaceType || item.ContentType || item.ConnectivityType || item.Description || item.vpnIp);
      });
    }
    
    
    if (isNotEmpty) {

      for (let i = 0; i < this.ConnectivityFormGroup.value.ProjInterfacecard.length; i++) {
        let uniobjs = {
          interfaceType: this.ConnectivityFormGroup.value.ProjInterfacecard[i].interfaceType,
          direction:'',
          connectivityDetails: {},
          contentType: '',
          description:'',
          ConnectivityType:'',
        };

        let conndetail = {
          vpnIp: this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnIp !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnIp : "",
          vpnHost: this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnHost !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnHost : "",
          vpnPort: this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnPort !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnPort : "",
          externalFtpUsername: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpUsername !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpUsername : "",
          externalFtpPassowrd: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpPassowrd !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpPassowrd : "",
          externalFtpHost: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpHost !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpHost : "",
          externalFtpFolderPath: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpFolderPath !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpFolderPath : "",
          externalFtpRemotePath: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpRemotePath !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpRemotePath : "",
          externalFtpArchivePath: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpArchivePath !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpArchivePath : "",
          externalFtpPort: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpPort !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpPort : "",
          deviceIp:this.ConnectivityFormGroup.value.ProjInterfacecard[i].DeviceIp !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].DeviceIp : "",
          devicePort:this.ConnectivityFormGroup.value.ProjInterfacecard[i].DevicePort !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].DevicePort : "",
          //deviceAgentId:this.ConnectivityFormGroup.value.ProjInterfacecard[i].DeviceAgentid !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].DeviceAgentid : "",
          deviceSubType:this.isdevice?this.DeviceFormGroup.value.JobSubType:"",
          vbagentName:this.isdevice?this.DeviceFormGroup.value.VBAgent:"",
          deviceAgentId:this.isdevice?this.selectedVBAgentId:"",
          deviceName:this.isdevice?this.selecteddevicename:"",
          deviceMessageVersion:this.isdevice?this.selectedmessageversion:"",
          deviceMessageFormat:this.isdevice?this.selectedMessageFormat:"",
          deviceType:this.isdevice?this.selectedDeviceType:"",
          deviceConnectivityType:this.isdevice?this.selectedConnectivityType:"",
          deviceBufferSize:this.isdevice?this.selectedBufferSize:"",
          SendReflexTestOrder:this.isdevice?this.selectedSendReflexTestOrder:"",
          ReflexDeviceId:this.isdevice?this.selectedReflexDeviceId:"",
          ReflexDeviceName:this.isdevice?this.selectedReflexDeviceName:"",
          CheckSumType:this.isdevice?this.selectedCheckSumType:"",
          ModelInfo:this.isdevice?this.ModelInfo:"",
          sourceorgguid:this.selectedlabobj!== undefined ? this.selectedlabobj.orgguid : "",
          sourceDeploymentKey: this.selectedlabobj !== undefined ? this.selectedlabobj.deploymentkey : "",
          metadata: serializedMetadata ? JSON.parse(serializedMetadata) : {}
        }

        //formsubmit
        uniobjs.interfaceType= this.ConnectivityFormGroup.value.ProjInterfacecard[i].interfaceType;
        uniobjs.direction=this.IType.find(va => this.ConnectivityFormGroup.value.ProjInterfacecard[i].interfaceType == va.lookupvalue).lookupid
        uniobjs.contentType=this.ConnectivityFormGroup.value.ProjInterfacecard[i].ContentType,
        uniobjs.description=  this.ConnectivityFormGroup.value.ProjInterfacecard[i].Description,
        uniobjs.ConnectivityType=this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType
        // if (this.ConnectivityFormGroup.value.ConnectivityType === 'vpn' && this.ConnectivityFormGroup.value.InterfaceType[0].includes('In')) {
        if (
          this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType === "VPN" ||
          this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType === "External FTP"||
          this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType === "Device"
        ) {

          uniobjs.connectivityDetails= conndetail

        }

        if (
          this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType === "API" ||
          this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType === "NONE" ||
          this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType === "FTP"
        ) {
            uniobjs.connectivityDetails= ""

        }
        uniobjs.direction=uniobjs.direction=='EllkayOutbound'?'Outbound':uniobjs.direction;
          this.oobj.push(uniobjs);
      }
      if(this.isdevice && this.oobj.length>0 && this.oobj[1]!=undefined){
        if((this.oobj[0]['connectivityDetails']['deviceIp']!=this.oobj[1]['connectivityDetails']['deviceIp']) &&
        (this.oobj[0]['connectivityDetails']['devicePort']!=this.oobj[1]['connectivityDetails']['devicePort']))
        {
          this.toastr.warning("IP and Port for Inbound and Outbound should be same", "", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
          return '';
        }
      }
    }
    else{
      let uniobjs = {
        interfaceType: '',
        direction:'',
        connectivityDetails: {},
        contentType: '',
        description:'',
        ConnectivityType:'',
      };

      let conndetail = {
        deviceSubType:this.isdevice?this.DeviceFormGroup.value.JobSubType:"",
        vbagentName:this.isdevice?this.DeviceFormGroup.value.VBAgent:"",
        deviceAgentId:this.isdevice?this.selectedVBAgentId:"",
        deviceName:this.isdevice?this.selecteddevicename:"",
        deviceMessageVersion:this.isdevice?this.selectedmessageversion:"",
        deviceMessageFormat:this.isdevice?this.selectedMessageFormat:"",
        deviceType:this.isdevice?this.selectedDeviceType:"",
        deviceConnectivityType:this.isdevice?this.selectedConnectivityType:"",
        deviceBufferSize:this.isdevice?this.selectedBufferSize:"",
        SendReflexTestOrder:this.isdevice?this.selectedSendReflexTestOrder:"",
        ReflexDeviceId:this.isdevice?this.selectedReflexDeviceId:"",
        ReflexDeviceName:this.isdevice?this.selectedReflexDeviceName:"",
        CheckSumType:this.isdevice?this.selectedCheckSumType:"",
        ModelInfo:this.isdevice?this.ModelInfo:"",
        sourceorgguid:this.selectedlabobj!== undefined ? this.selectedlabobj.orgguid : "",
        sourceDeploymentKey: this.selectedlabobj !== undefined ? this.selectedlabobj.deploymentkey : "",
        deviceIp:this.selectedVBIPAddress?this.selectedVBIPAddress:"",
        metadata: serializedMetadata ? JSON.parse(serializedMetadata) : {}
      }
    uniobjs.connectivityDetails= conndetail;

    this.oobj.push(uniobjs);
      
    }
    this.config = JSON.stringify(this.oobj);
    let contactDetails = [];
    if (this.LabFormGroup.value.labContactdetails.length > 0) {
      let obs = {
        lab: this.LabFormGroup.value.labContactdetails,
      };
      contactDetails.push(obs);
    }
    if (this.FacilityFormGroup.value.facilityContactdetails.length > 0) {
      let obs = {
        facility: this.FacilityFormGroup.value.facilityContactdetails,
      };
      contactDetails.push(obs);
    }
    if (this.VendorFormGroup.value.vendorContactdetails.length > 0) {
      let obs = {
        vendor: this.VendorFormGroup.value.vendorContactdetails,
      };
      contactDetails.push(obs);
    } 
    let obs = {
      requester: [
        {
          name: this.requesterFormGroup.value.requesterName,
          email: this.requesterFormGroup.value.requesterEmail,
          phone: this.requesterFormGroup.value.requesterPhone,
          id: this.reqID !== undefined ? this.reqID : this.reqNoID
        },
      ],
    };
    contactDetails.push(obs);

    let detail = JSON.stringify(contactDetails);
    let files = [];
    if (this.projFiles !== null) {
      for (let i = 0; i < this.projFiles.length; i++) {
        let docs = {
          fileContent: this.projFiles[i].fileContent,
          fileName: this.projFiles[i].filename,
          fileid: this.projFiles[i].fileid !== undefined ? this.projFiles[i].fileid : "",
          action: this.projFiles[i].action == "passive" ? "passive" : "active",
          id: this.projFiles[i].id !== undefined ? this.projFiles[i].id  : 0,
        };
        files.push(docs);
      }
    }
    if(this.isdevice){
      this.selectedfacilityobj=JSON.parse(JSON.stringify(this.selectedlabobj));
    }

    let obj = {
      isSubmit: false,
      userId: JSON.parse(sessionStorage.getItem("rolewithmenus"))[0].uniqueId,
      modifiedBy: JSON.parse(sessionStorage.getItem("rolewithmenus"))[0].UserID,
      projectID: this.projvaid !== undefined ? this.projvaid : "",
       id: this.projid !== undefined  &&this.projid !== "" ? this.projid : 0,
      projectType: "Interface",
      sourceGuid:
        this.selectedlabobj !== undefined
          ? this.selectedlabobj.LocationGUID
          : this.isEditLabGUID,
      sourceDeploymentKey: this.selectedlabobj !== undefined ? this.selectedlabobj.deploymentkey : "",
      sourceOrgId: this.selectedlabobj!== undefined ? this.selectedlabobj.organizationid : "",
      sourceorgguid:this.selectedlabobj!== undefined ? this.selectedlabobj.orgguid : "",
      sourceAccountId: this.selectedlabobj !== undefined ? this.selectedlabobj.entityid : "",
      sourceName: this.selectedlabobj !== undefined ? this.selectedlabobj.Displayname : "",
      sourceAccountType: this.selectedlabobj !== undefined ? this.selectedlabobj.EntityType : "",
      sourceAddress1: this.selectedlabobj !== undefined ? this.selectedlabobj.Address1 : "",
      sourceAddress2: this.selectedlabobj !== undefined ? this.selectedlabobj.Address2 : "",
      sourceCity: this.selectedlabobj !== undefined ? this.selectedlabobj.city : "",
      sourceState: this.selectedlabobj !== undefined ? this.selectedlabobj.state : "",
      sourceZip: this.selectedlabobj !== undefined ? this.selectedlabobj.zip : "",
      sourceType: this.selectedlabobj !== undefined ? this.selectedlabobj.TypeName : "",
      sourceMnemonic: this.selectedlabobj !== undefined ? this.selectedlabobj.mnemonic  : "",
      payingLabGuid:
        this.selectedpaylabobj !== undefined
          ? this.selectedpaylabobj.LocationGUID
          : this.isEditPayingGUID,
      payingLabName: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.Displayname : "",
      payingLabDeploymentKey: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.deploymentkey : "",
      payingLabAccountType: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.EntityType : "",
      payingLabAddress1: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.Address1 : "",
      payingLabAddress2: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.Address2 : "",
      payingLabCity: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.city : "",
      payingLabState: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.state : "",
      payingLabZip: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.zip : "",
      payingLabType: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.TypeName : "",
      contactDetails: detail,
      vendorID: this.vendoID,
      vendorName: this.vendoName,
      appName: this.VendorFormGroup.value.ApplicationName,
      appVersion: this.VendorFormGroup.value.ApplicationVersion,
      vendorMnemonic: this.venMnemonic,
      requesterName: this.requesterFormGroup.value.requesterName,
      destinationGUID:
        this.selectedfacilityobj !== undefined
          ? this.selectedfacilityobj.LocationGUID
          : this.isEditFaciltyGUID,
      destinationDeploymentKey: this.selectedfacilityobj !== undefined  ? this.selectedfacilityobj.deploymentkey : "",
      destinationOrgId: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.organizationid : "",
      destinationorgguid:this.selectedfacilityobj !== undefined ?this.selectedfacilityobj.orgguid : "",
      destinationAccountId: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.entityid : "",
      destinationName: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.Displayname: "",
      destinationAddress1: this.selectedfacilityobj !== undefined && this.selectedfacilityobj.Address1 !== ''? this.selectedfacilityobj.Address1 : this.FacilityFormGroup.value.facilityaddress,
      destinationAddress2: this.selectedfacilityobj !== undefined &&  this.selectedfacilityobj.Address2 !=='' ? this.selectedfacilityobj.Address2 : this.FacilityFormGroup.value.facilityaddress,
      destinationAccountType: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.EntityType : "",
      destinationCity: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.city : "",
      destinationState: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.state : "",
      destinationZip: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.zip : "",
      destinationType: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.TypeName : "",
      destinationMnemonic: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.mnemonic : "",
      projectName: this.DetailsFormGroup.value.projName,
      metadata: serializedMetadata,
      projectScope: this.DetailsFormGroup.value.projScopeRequirement,
      kickOffDate:
        (this.DetailsFormGroup.value.kickOffDate !== undefined && this.DetailsFormGroup.value.kickOffDate !== null)
          ? this.formatDate(this.DetailsFormGroup.value.kickOffDate)
          : "",
      goLiveDate:
        (this.DetailsFormGroup.value.goLiveDate !== undefined && this.DetailsFormGroup.value.goLiveDate !== null)
          ? this.formatDate(this.DetailsFormGroup.value.goLiveDate)
          : "",
      projectStatus: "",
      interfaceBetween: this.LabFormGroup.value.interfaceBetween,
      projectDocs: files,
      otherComments: this.DetailsFormGroup.value.otherComments,
      specialityCaseType:this.DetailsFormGroup.value.specialityCaseType,
      interfaces: this.config !== "" ? this.config : "",
    };
    // obj.interfaces = config;
    // let isflag

    if (isSubmitForms === true) {
      if (this.LabFormGroup.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.LabFormGroup.controls.labContactdetails.status == "INVALID") {
        this.toastr.warning("Invalid contact details", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.requesterFormGroup.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Requester Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }

      if (this.FacilityFormGroup.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Facility Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (
        this.FacilityFormGroup.controls.facilityContactdetails.status ==
        "INVALID"
      ) {
        this.toastr.warning("Invalid contact details", "Facility Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (
        this.VendorFormGroup.controls.vendorContactdetails.status == "INVALID"
      ) {
        this.toastr.warning("Invalid contact details", "Vendor Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.ConnectivityFormGroup.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Connectivity Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if(this.isdevice && this.DeviceFormGroup==undefined && this.DeviceFormGroup.status == "INVALID"){
        this.toastr.warning("required fields are empty:", "Device Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
        return '';
      }

      if (
        this.ConnectivityFormGroup.status !== "INVALID" &&
        this.LabFormGroup.status != "INVALID" &&
        this.requesterFormGroup.status != "INVALID" &&
        this.FacilityFormGroup.status !== "INVALID" &&
        this.DetailsFormGroup.status !== "INVALID"
      ) {
          obj.isSubmit = true;
          obj.vendorName = this.vendoName
          this.saveorsubmitformapi(obj,'Interface','submit');
      }
    }

    else {
      this.saveorsubmitformapi(obj,'Interface','save');
    }
  }
  
  formatDate(date) {
    if (date !== '') {
      var last = new Date(date);
      var day = ("0" + last.getDate()).slice(-2);
      var month = ("0" + (last.getMonth() + 1)).slice(-2);
      var year = last.getFullYear();
      return year + "-" + month + "-" + day;
    }
  }
  showSuccessform() {
    this.toastr.success("Submitted Successfully", "Interface Request Form", {
      positionClass: "toast-bottom-right",
      timeOut: 4000,
    });
  }
  getFormListdata(value: any) {
    this.ngxuiloader.start();
    this.commonService.getformdata(value).subscribe(
      (data) => {
        this.ngxuiloader.stop();
        if (data.length > 0) {
          this.viewdata = data;
        }
      },
      (error) => {
        this.ngxuiloader.stop();
      }
    );
  }

  uniqdata =[]
  batchArray =[]

  getsourceInfo(value){
    if(value !== undefined){
      this.selectedlabobj={}
       this.selectedlabobj['LocationGUID'] = value.sourceGuid
       this.selectedlabobj['deploymentkey']=value.sourceDeploymentKey,
       this.selectedlabobj['organizationid '] = value.sourceOrgId,
       this.selectedlabobj['entityid'] =value.sourceAccountId
       this.selectedlabobj['Displayname'] =value.sourceName
       this.selectedlabobj['EntityType '] =value.sourceAccountType,
       this.selectedlabobj['Address1'] =value.sourceAddress1
       this.selectedlabobj['Address2'] =value.sourceAddress2
       this.selectedlabobj['city'] =value.sourceCity
       this.selectedlabobj['state'] =value.sourceState
       this.selectedlabobj['zip'] =value.sourceZip;
       this.selectedlabobj['TypeName'] =value.sourceType;
       this.selectedlabobj['mnemonic'] =value.sourceMnemonic;
       if (this.selectedlabobj) {
        this.displaydeploy = this.selectedlabobj.deploymentkey + "(L)";
      }
      if(this.selectedlabobj.deploymentkey !== '' && this.displaydeploy !== undefined) {
        this.deploy.setValue(this.displaydeploy + " , " + this.displaydeploy2);
      }

    }
  }
  getTargetInfo(value){
    if(value !== undefined){
      this.selectedfacilityobj={}
      this.selectedfacilityobj.LocationGUID=value.destinationGUID
       this.selectedfacilityobj.deploymentkey = value.destinationDeploymentKey
      this.selectedfacilityobj.organizationid=value.destinationOrgId
        this.selectedfacilityobj.entityid=value.destinationAccountId
        this.selectedfacilityobj.Displayname =value.destinationName
       this.selectedfacilityobj.Address1 = value.destinationAddress1
        this.selectedfacilityobj.Address2 = value.destinationAddress2
        this.selectedfacilityobj.EntityType=value.destinationAccountType
        this.selectedfacilityobj.city=value.destinationCity
        this.selectedfacilityobj.state = value.destinationState
       this.selectedfacilityobj.zip = value.destinationZip
        this.selectedfacilityobj.TypeName=value.destinationType
        this.selectedfacilityobj.mnemonic =value.destinationMnemonic

       if(this.selectedlabobj ) {
        this.displaydeploy2 = this.selectedfacilityobj.deploymentkey + "(F)";
      }
      if(this.selectedfacilityobj.deploymentkey !=='' && this.displaydeploy2 !== undefined){
        this.deploy.setValue(this.displaydeploy + " , " + this.displaydeploy2);
      }

    }
  }
  getFilesInfo(value: any, batch : any) {
    this.errorRecordsFilename=''
    this.batchArray=[]
this.selectedPID = value;
this.selectedBatchID = batch;
    if(value!== null || value!==undefined && batch !== null || batch !== undefined){
     this.uniqdata = this.importDataList.find(va => va.id === value)

      this.filesList = [];
      this.batchIndiStatus = [];
      if(this.uniqdata['fileContentList'] !== null){
        let batchItemList = this.uniqueArr(this.uniqdata['fileContentList'] , this.filesList)
        this.uniqdata['fileList'] = batchItemList.sort((a,b) => a.BatchID - b.BatchID);
      for (let j = 0; j < this.uniqdata['fileContentList'].length; j++) {
        // let batchList = this.uniqueArr(this.ViewInterfaceData[i].fileContentList, this.filesList);

        // this.ViewInterfaceData[i]['fileList'] = batchList

        if (this.uniqdata["fileContentList"][j]["CurrentRecords"] > 0) {
          this.uniqdata["fileContentList"][j]["progress"] =
            Math.round(
              (this.uniqdata["fileContentList"][j]["CurrentRecords"] *
                100) /
              this.uniqdata["fileContentList"][j]["TotalRecords"]
            );
        } else {
          this.uniqdata["fileContentList"][j]["progress"] = 100;
        }
      }

      // this.batchStatus = this.batchIndiStatus[0]
    }
      this.batchArray = this.uniqdata['fileContentList'].filter(va => va.BatchID === batch);


this.batchArray.forEach(element=>
  {
    if(element.errorFileName!='')
    {
      element["showErrorrecordsButtons"] = true;
    }
  })


    }
}
  viewErrorRecords(errorFileName) {
       let obj = {
      PID: this.selectedPID,
      errorFileName: errorFileName
    }
    this.ngxuiloader.start()
    this.commonService.getErrorFileInfo(obj).toPromise().then(async response => {
      this.ngxuiloader.stop()
      if (response.responsebody != null) {
        const byteCharacters = atob(response.responsebody);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: 'application/csv' });
        const objectUrl = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = objectUrl;
        anchor.download = this.batchArray[0].errorFileName
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(objectUrl);
      } else {
        this.toastr.error("Failed to Get Error Records Files", "", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
    });
  }


  checkeStatus(array,ref,flag,isdraft){
    if(array.length>0){
      let list = array.filter(va =>va.BatchID == ref);
      if(list.length>0){
        for (let k = 0; k < list.length; k++) {
          if (list[k]['Status'] == 'Failed' ||
            list[k]['Status'].toLowerCase() == 'Error') {
               let status = list.filter(va => va.Status == 'Failed')
              if(flag == 'status'){
                return 'Failed'
              }else{
                return status.length
              }

            // this.batchIndiStatus.push(statu)
          }

          if (
            list[k]['Status'] == 'InProgress' ||
            list[k]['Status'] == 'Initial' ||
            list[k]['Status'] == 'Waiting') {
              let statusPro = list.filter(va=> va.Status == 'InProgress')
              let statusIni = list.filter(va=> va.Status == 'Initial')
              let statusWai = list.filter(va=> va.Status == 'Waiting')
              if(flag == 'status'){
                if(isdraft.toLowerCase() === 'draft'){
                  return 'Initial'
                }else{
                return 'In Progress'
                }
              }else if(statusPro.length>0){
                return statusPro.length
              }
              else if(statusWai.length > 0){
                return statusWai.length
              }
              else{
                return statusIni.length
              }


            // this.batchIndiStatus.push(statu)
          }
          if (list[k]['Status'] == 'Success') {
              let statusSuc = list.filter(va=> va.Status == 'Success')
              if(flag == 'status'){
                return'Success'
              }else{
                return statusSuc.length
              }

            // this.batchIndiStatus.push(statu)
          }
          if (list[k]['Status'] == 'Completed') {
              let statusComp = list.filter(va=> va.Status == 'Completed')
              if(flag == 'status'){
                return 'Completed'
              }else{
                return statusComp.length
              }

            // this.batchIndiStatus.push(statu)
          }
          if (list[k]['Status'] == 'Processing') {
            let statusComp = list.filter(va=> va.Status == 'Processing')
            if(flag == 'status'){
              return 'Processing'
            }else{
              return statusComp.length
            }

          // this.batchIndiStatus.push(statu)
        }
        if(list[k]['Status']=='RequestReceived'){
          let statusReq = list.filter(va=> va.Status == 'RequestReceived')
          if(flag=='status'){
            return 'Request Received'
          }
          else{
            return 0
          }
        }
        }
      }

    }
  }

  async getInterfaceFormList(value: any) {
    this.pendingProjectCount =0
    this.isTabName = value;
    this.ngxuiloader.start();
    let obj = {
      projecttype: value,
      createdfrom: "",
      createdto: "",
      createdBy: "",
      sourceGuid: "",
      destinationGuid: "",
      id: this.objid,
      projectID: "",
      requester: "",
      projectName: "",
    };
    this.ngxuiloader.start();
 await   this.commonService.InterfaceFormList(obj).subscribe(
      (data) => {

        this.ngxuiloader.stop();
        if (data.status === "success") {
          this.createFtpinter()
          switch (value) {
            case 'Interface':
          this.ViewInterfaceData = JSON.parse(data.statusmessage);
              break;
            case 'Import':
              if(data.statusmessage=="No data found")
               {
                 this.importDataList=[];
                 return
               }
              this.importDataList = JSON.parse(data.statusmessage);
              this.submitbutton= this.importDataList[0]['Projectstatus']
              for (let i = 0; i < this.importDataList.length; i++) {

                this.pendingProjectCount=this.importDataList.filter(va=>va.projectStatus=='Get Approval').length;
              this.importDataList.forEach(element=>{
                if(element.status !== null){
                  element.projectStatus =element.status
                }
              })
                if (this.importDataList[i]['fileContentList'] !== null) {
                  for (let j = 0; j < this.importDataList[i]['fileContentList'].length; j++) {
                    this.getFilesInfo(this.importDataList[i].id, this.importDataList[i]['fileContentList'][j].BatchID)
                  }
                }
            }
              break;
            case 'Export':
              if(data.statusmessage=="No data found")
              {
                this.exportDataList=[];
                return
              }
              this.exportDataList = JSON.parse(data.statusmessage)
              this.pendingProjectCount=this.exportDataList.filter(va=>va.projectStatus=='Get Approval').length;
              this.exportDataList.forEach(element=>{
                if(element.status !== null){
                  element.projectStatus =element.status
                }
              })
              break;
            default:
              this.ViewInterfaceData = JSON.parse(data.statusmessage);
              break;
            }

          // if (this.isTabName == 'Import') {

          //   }
          }

        else {
          this.toastr.error(data.statusmessage, "", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
      },
      (error) => {
        this.ngxuiloader.stop();
        this.toastr.error(error.statusText, "", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
    );

  }

  createFtpinter(){
    // this.intervalim = setInterval(() => {
    //   this.commonService.getUpdateFTPInternal().subscribe(data =>{})
    // },30000)
  }

  finaliseform(id: number) {
    let obj = {
      interfaceRequestID: id,
      edit_mode: "false",
      SubmitResult: "1",
    };
    this.ngxuiloader.start();
    this.commonService.finalizeformdata(obj).subscribe(
      (data) => {
        this.ngxuiloader.stop();
        if (data > 0) {
          this.getFormListdata(0);
        }
      },
      (error) => {
        this.ngxuiloader.stop();
      }
    );
  }


  viewIndi(id,batch) {

    $("#viewIndividualFilesList").appendTo("body").modal("show");
    this.getFilesInfo(id,batch);
  }


  viewformpop(id, type,isproject:string,modalrefid) {

    this.isproject=isproject;
    this.ngxuiloader.start();
    let arr = []

    this.viewlabCont = []
    this.viewReqCont = []
    this.viewFacCont = []
    this.viewVenCont = []
    // $("#exampleModalForm").appendTo("body").modal("show");
    this.modalService
    .open(modalrefid, {
      windowClass: "saveVendormodal",
      backdrop: "static",
      keyboard: false,
    })
    .result.then((result) => {});
    this.commonService.ProjectFormEdit(id, type).subscribe((data) => {
      if (data) {
        this.ngxuiloader.stop();
        this.viewform = JSON.parse(data.statusmessage)

        if (this.viewform['projectStatus'] == 'submit') {
          this.issub = 'Submit'
        }
        else {
          this.issub = 'Save'
        }

        if(this.viewform['contactDetails'] !== ''){

        arr = JSON.parse(this.viewform['contactDetails'])

        this.viewlabCont = arr.filter(x => x.ContactType == 'lab')
        this.viewReqCont = arr.filter(x => x.ContactType == 'requester')
        this.viewFacCont = arr.filter(x => x.ContactType == 'facility')
        this.viewVenCont = arr.filter(x => x.ContactType == 'vendor')
          this.listaddress('interface');

        }

        if(this.viewform['interfaces'] !== ''){
        this.viewInterfaces = JSON.parse(this.viewform['interfaces'])
        // this.Ftpdetails = JSON.parse(this.viewform['projectVaFtpDetails'])

      }
      this.projInterfacesList(id);
      }
      else {
        this.toastr.error("Failed!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.ngxuiloader.stop();
      }
    })
    // this.viewform = this.ViewInterfaceData.find(x=> x.id == id)
  }

async  viewImporExpForm(id, type,isproject:string,modalrefid,status) {
  this.sendmail=false
    if(status=='Get Approval' && this.pagerolepermisions.ApproveAndreject)
    {
     this.ShowApproveAndRejectButton=true;
    }
    else if(isproject=='import'||isproject=='export'){
      this.ShowApproveAndRejectButton=false;
    }
    this.isproject=isproject;
    this.ngxuiloader.start();
    this.projectidPid=id;
    let arr = []
    this.viewImport=[]
    this.viewImpLab =[]
    this.viewImpReq  =[]
    this.viewVendorImp =[]
    this.viewlabCont = []
    this.viewReqCont = []
    this.viewFacCont = []
    this.viewVenCont = []
    this.viewexport=[]
    this.viewexpLab =[]
    this.viewexpReq  =[]
    this.viewVendorexp =[]

    this.modalService
    .open(modalrefid, {
      windowClass: "saveVendormodal",
      backdrop: "static",
      keyboard: false,
    })
    .result.then((result) => {});

    this.commonService.ProjectFormEdit(id, type).subscribe(async (data) => {
      if (data) {
        this.ngxuiloader.stop();
        if (data.status.toLowerCase() === "failure") {
          this.isedit = false;
          this.ngxuiloader.stop();
          this.toastr.error("Failed!", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          let arr = JSON.parse(data.statusmessage);
          this.ImpID = arr.id;
          this.projImpID = arr.projectID;
          this.isedit = true;
        if(isproject=='import')
        {
        this.viewImport = JSON.parse(data.statusmessage)
        arr = JSON.parse(this.viewImport['contactDetails'])
        this.viewImpLab = arr.filter(x => x.ContactType == 'lab')
        this.viewImpReq = arr.filter(x => x.ContactType == 'requester')
        this.viewVendorImp = arr.filter(x => x.ContactType == 'vendor')

      await  this.patchformImpExpValue(data.statusmessage,'import');
        }
        else if(isproject=='export'){
          this.viewexport = JSON.parse(data.statusmessage)
        arr = JSON.parse(this.viewexport['contactDetails'])
        this.viewexpLab = arr.filter(x => x.ContactType == 'lab')
        this.viewexpReq = arr.filter(x => x.ContactType == 'requester')
        this.viewVendorexp = arr.filter(x => x.ContactType == 'vendor')
        await  this.patchformImpExpValue(data.statusmessage,'export')
        }
        else if(isproject=='interface'){
          this.viewform = JSON.parse(data.statusmessage)
        if (this.viewform['projectStatus'] == 'submit') {
          this.issub = 'Submit'
        }
        else {
          this.issub = 'Save'
        }
        if(this.viewform['contactDetails'] !== ''){
        arr = JSON.parse(this.viewform['contactDetails'])
        this.viewlabCont = arr.filter(x => x.ContactType == 'lab')
        this.viewReqCont = arr.filter(x => x.ContactType == 'requester')
        this.viewFacCont = arr.filter(x => x.ContactType == 'facility')
        this.viewVenCont = arr.filter(x => x.ContactType == 'vendor')
          this.listaddress('interface');
        }
        if(this.viewform['interfaces'] !== ''){
        this.viewInterfaces = JSON.parse(this.viewform['interfaces'])
        this.viewdeviceName = this.viewInterfaces[0].connectivityDetails.deviceName;
        this.viewvbAgentName = this.viewInterfaces[0].connectivityDetails.vbagentName;
        this.viewdeviceType = this.viewInterfaces[0].connectivityDetails.deviceType;
        this.viewdeviceIp = this.viewInterfaces[0].connectivityDetails.deviceIp;
        this.viewdevicePort = this.viewInterfaces[0].connectivityDetails.devicePort;
        this.viewMetaData = JSON.stringify(this.viewInterfaces[0].connectivityDetails.metadata);
      }
      this.projInterfacesList(id);
      return;
        }
      }
      }
      else {
        this.toastr.error("Failed!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.ngxuiloader.stop();
      }
    })
  }

  newformpop() {
    this.isShowCard = !this.isShowCard;
    this.resetRequestForm();
  }
  closeCreateProject() {
    this.isShowCard = false;
    this.resetRequestForm();
    this.onTabChanged(this.selectedIndexTab)
  }

  closeInterfaceViewProject(ref) {
    ref("close modal");
  }

  closeImportViewProject() {
    this.activeModal.close('ImportForm');
  }
  refreshPatient(){

    this.ngxuiloader.start()
this.commonService.refreshpatientImport(this.selectedPID).subscribe(data =>{

  this.ngxuiloader.stop();
if(data.length>0){
  this.batchArray = data.filter(va=>va.BatchID == this.selectedBatchID);
  for (let j = 0; j < this.batchArray.length; j++) {
        // let batchList = this.uniqueArr(this.ViewInterfaceData[i].fileContentList, this.filesList);

        // this.ViewInterfaceData[i]['fileList'] = batchList

        if (this.batchArray[j]["CurrentRecords"] > 0) {
          this.batchArray[j]["progress"] =
            Math.round(
              (this.batchArray[j]["CurrentRecords"] *
                100) /
                this.batchArray[j]["TotalRecords"]
            );

        } else {
          this.batchArray[j]["progress"] = 100;
        }
        if(this.batchArray[j].errorFileName!=null)
        {
          this.batchArray[j]["showErrorrecordsButtons"] = true;
        }
      }

  // this.getFilesInfo(this.selectedPID,this.selectedBatchID);/
}
},error =>{
  this.ngxuiloader.stop();
})
  }
  closeViewFile() {
    this.activeModal.close('viewIndividualFilesList');
  }

  
  clearFormArray = (formArray : FormArray) =>{
    formArray = this._formBuilder.array([]);
  }

  editImportExpProjForm(id, type,status) {
    this.sendmail=false
    if(status=='Get Approval' && this.pagerolepermisions.ApproveAndreject)
    {
     this.ShowApproveAndRejectButton=true;
     this.showProjectSubmitButton =false;
     this.ShowsaveButton=false
    }
    else{
      this.ShowsaveButton=true
    }
    if(status=='' && this.pagerolepermisions.ApproveAndreject && type.toLowerCase()!='interface')
    {
     this.showProjectSubmitButton=true;
    }
    this.ngxuiloader.start();
    this.projectidPid=id;

    this.commonService
          .ProjectFormEdit(id, type)
          .toPromise()
          .then(
            (data) => {
              if (data.status.toLowerCase() === "failure") {
                this.isedit = false;
                this.ngxuiloader.stop();
                this.toastr.error("Failed!", "", {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                });
              } else {
                let arr = JSON.parse(data.statusmessage);
                this.isedit = true;
                this.isShowCard = true;

                if(type.toLowerCase()=='import'){
                  this.ImpID = arr.id;
                  this.projImpID = arr.projectID;
                  console.log("beforepatch")
                  this.patchformImpExpValue(data.statusmessage,'import');
                  console.log("after pathch")
                }
                else if(type.toLowerCase()=='export'){
                  this.ExpID = arr.id;
                  this.projExpID = arr.projectID;
                  this.patchformImpExpValue(data.statusmessage,'export');
                }
                else if(type.toLowerCase()=='interface'){
                  this.clearFormArray(this.ConnectivityFormGroup.value.ProjInterfacecard)
                  this.projid = arr.id;
                  this.projvaid = arr.projectID;
                  this.patchformvalue(data.statusmessage);
                }
              }
              this.ngxuiloader.stop();
            }


    );
  }


  addLab(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    if (value) {
      this.Labs.push(value);
    }
    // Clear the input value
    if (event.input) {
      event.input.value = "";
    }
    this.LabCtrl.setValue(null);
  }
  removeLab(Lab: string): void {
    const index = this.Labs.indexOf(Lab);
    if (index >= 0) {
      this.Labs.splice(index, 1);
    }
  }
  selectedLab(event: MatAutocompleteSelectedEvent): void {
    this.Labs.push(event.option.viewValue);
    this.LabInput.nativeElement.value = "";
    this.labControl.setValue(null);
  }
  private _filterLab(value: string): string[] {
    const filterLabValue = value.toLowerCase();
    return this.allLabs.filter((lab) =>
      lab.toLowerCase().includes(filterLabValue)
    );
  }
  ///////////
  ///////////

  addpayingLab(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    // Add our fruit
    if (value) {
      this.payingLabs.push(value);
    }
    // Clear the input value
    if (event.input) {
      event.input.value = "";
    }
    this.payinglabControl.setValue(null);
  }
  removepayingLab(payingLab: string): void {
    const index = this.payingLabs.indexOf(payingLab);
    if (index >= 0) {
      this.payingLabs.splice(index, 1);
    }
    this.LabFormGroup.patchValue({ payingLab: this.payingLabs });
  }
  selectedpayingLab(event: MatAutocompleteSelectedEvent): void {
    this.payingLabs.push(event.option.viewValue);
    this.payingLabInput.nativeElement.value = "";
    this.payinglabControl.setValue(null);
    this.LabFormGroup.patchValue({ payingLab: this.payingLabs });
  }
  private _filterpayingLab(payinglabvalue: string): string[] {
    const filterpayinglabValue = payinglabvalue.toLowerCase();
    return this.allpayingLabs.filter((payingLab) =>
      payingLab.toLowerCase().includes(filterpayinglabValue)
    );
  }
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  intTypeSelect: string[];
  optionClick(event, index) {

    let newStatus = event.checked;
    // this.onAddNewCard().clear();
    this.intTypeSelect = event.value;
    let uniqarrayform =JSON.parse(JSON.stringify(this.ConnectivityFormGroup.value.ProjInterfacecard))
    for (let k = 0; k <  this.intTypeSelect.length; k++) {
       uniqarrayform = uniqarrayform.filter(va => va.interfaceType !==  this.intTypeSelect[k])
    }
    if(uniqarrayform.length > 0) {
      for (let l = 0; l < uniqarrayform.length; l++) {
        let ind =  this.ConnectivityFormGroup.value.ProjInterfacecard.findIndex( va => va.interfaceType ==  uniqarrayform[l].interfaceType )
      this.removeConnCard(ind)

      }

    }

    // this.ConnectivityFormGroup.patchValue({
    //   ProjInterfacecard :uniqarrayform
    // })
    // this.select.options.forEach((item: MatOption) => {
    //   if (!item.selected) {
    //     newStatus = false;
    //   }
    // });
    if (this.intTypeSelect.length === 0) {
      newStatus = false;
    }

    for (let i = 0; i < this.intTypeSelect.length; i++) {
      this.getNewConnCard(this.intTypeSelect[i]);

      // this.ConnectivityFormGroup.value.ProjInterfacecard.filter(va =>va.InterfaceName == )

    }

    this.ConnectivityFormGroup;
    this.addvalidation();

    this.allSelected = newStatus;
  }

  addvalidation() {
    if (
      // this.ConnectivityFormGroup.value.InterfaceType.length > 0
      this.DetailsFormGroup.value.ProjInterfaceType.length > 0
    ) {
      let isallowin = false;
      this.isInbound = false;

      // for (let i = 0; i < this.ConnectivityFormGroup.value.InterfaceType.length; i++) {
      for (
        let i = 0;
        i < this.DetailsFormGroup.value.ProjInterfaceType.length;
        i++
      ) {
        if (this.DetailsFormGroup.value.ProjInterfaceType[i].includes("In")) {
          // if (this.ConnectivityFormGroup.value.InterfaceType[i].includes("In")) {
          isallowin = true;
          this.isInbound = true;
        }

        if (
          isallowin &&
          this.ConnectivityFormGroup.controls["ContentType"] !== undefined
        ) {
          this.ConnectivityFormGroup.controls["ContentType"].setValidators(
            Validators.required
          );
          this.ConnectivityFormGroup.controls[
            "ContentType"
          ].updateValueAndValidity();
        } else {
          if (
            this.ConnectivityFormGroup.controls["ContentType"] !== undefined
          ) {
            this.ConnectivityFormGroup.controls[
              "ContentType"
            ].clearValidators();
            this.ConnectivityFormGroup.controls[
              "ContentType"
            ].updateValueAndValidity();
          }
        }
      }
    }

    if (
      // this.ConnectivityFormGroup.value.InterfaceType.length > 0
      this.DetailsFormGroup.value.ProjInterfaceType.length > 0
    ) {
      let isallow = false;
      this.isOutbound = false;
      // for (let i = 0; i < this.ConnectivityFormGroup.value.InterfaceType.length; i++) {
      for (
        let i = 0;
        i < this.DetailsFormGroup.value.ProjInterfaceType.length;
        i++
      ) {
        if (this.DetailsFormGroup.value.ProjInterfaceType[i].includes("Out")) {
          // if (this.ConnectivityFormGroup.value.InterfaceType[i].includes("Out")) {
          isallow = true;
          this.isOutbound = true;
        }
      }

      if (isallow && this.ConnectivityFormGroup.controls["ContentType"] !== undefined) {
        this.ConnectivityFormGroup.controls["ContentType"].setValidators(
          Validators.required
        );
        this.ConnectivityFormGroup.controls[
          "ContentType"
        ].updateValueAndValidity();

      } else {
        if(this.ConnectivityFormGroup.controls["ContentType"]!== undefined){
          this.ConnectivityFormGroup.controls["ContentType"].clearValidators();
          this.ConnectivityFormGroup.controls[
            "ContentType"
          ].updateValueAndValidity();
        }

      }
    }

    // if (
    //   this.ConnectivityFormGroup.value.ConnectivityType === "api" &&
    //   // this.ConnectivityFormGroup.value.InterfaceType.includes("In") &&
    //   this.DetailsFormGroup.value.ProjInterfaceType.includes("In") &&
    //   !this.ConnectivityFormGroup.value.isVAAPI_Inbound
    // ) {
    //   this.ConnectivityFormGroup.controls["Endpoint_Inbound"].setValidators(
    //     Validators.required
    //   );
    //   this.ConnectivityFormGroup.controls[
    //     "Endpoint_Inbound"
    //   ].updateValueAndValidity();
    // } else {
    //   this.ConnectivityFormGroup.controls["Endpoint_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls[
    //     "Endpoint_Inbound"
    //   ].updateValueAndValidity();
    // }

    // if (
    //   this.ConnectivityFormGroup.value.ConnectivityType === "api" &&
    //   // this.ConnectivityFormGroup.value.InterfaceType.includes("Out") &&
    //   this.DetailsFormGroup.value.ProjInterfaceType.includes("Out") &&
    //   !this.ConnectivityFormGroup.value.isVAAPI_Outbound
    // ) {
    //   this.ConnectivityFormGroup.controls["Endpoint_Outbound"].setValidators(
    //     Validators.required
    //   );
    //   this.ConnectivityFormGroup.controls[
    //     "Endpoint_Outbound"
    //   ].updateValueAndValidity();
    // } else {
    //   this.ConnectivityFormGroup.controls[
    //     "Endpoint_Outbound"
    //   ].clearValidators();
    //   this.ConnectivityFormGroup.controls[
    //     "Endpoint_Outbound"
    //   ].updateValueAndValidity();
    // }

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.ConnectivityFormGroup.value.InterfaceType.includes("In")) {

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.DetailsFormGroup.value.ProjInterfaceType.includes("In")) {
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["IP_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Host_Inbound"].updateValueAndValidity();
    // } else {
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["IP_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Host_Inbound"].updateValueAndValidity();
    // }

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.ConnectivityFormGroup.value.InterfaceType.includes("Out")) {

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.DetailsFormGroup.value.ProjInterfaceType.includes("Out")) {
    //   this.ConnectivityFormGroup.controls["Port_Outbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Port_Outbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Outbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["IP_Outbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Outbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Host_Outbound"].updateValueAndValidity();
    // } else {
    //   this.ConnectivityFormGroup.controls["Port_Outbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Port_Outbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Outbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["IP_Outbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Outbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Host_Outbound"].updateValueAndValidity();
    // }

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.ConnectivityFormGroup.value.InterfaceType.includes("In")) {

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.DetailsFormGroup.value.ProjInterfaceType.includes("In")) {
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["IP_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Host_Inbound"].updateValueAndValidity();
    // } else {
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["IP_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Host_Inbound"].updateValueAndValidity();
    // }
  }



  ngOnInit() {
    const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'interfacerequestform').MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
    actionitems.forEach(element => {
      this.pagerolepermisions[element.MenuName] = true;
    })
    this.vendorRefresh =  this.commonService.vendorUpdate.subscribe(data => {
      if(data){

       this.getVendorname()
      }
    })


    this.filteredtoaddressid = this.toaddressctrl.valueChanges.pipe(
      startWith(null),
      map((id: string | null) => (id ? this._filtertoaddress(id) : this.alltoaddressid.slice())),
    );

    this.filteredCcid = this.Cctrl.valueChanges.pipe(
      startWith(null),
      map((id: string | null) => (id ? this._filterCc(id) : this.allCcid.slice())),
    );
if(this.commonService.lasturl !== ''){
  this.onTabChanged(1)
  this.commonService.lasturl = '';
}
    this.getInterfaceFormList("Interface");
    this.getVendorname();
    this.getFormListdata(0);
    this.selectInttype();
    // this.addfacilitycontactfield();
    // this.addlabcontactfield();
    // this.addvendorcontactfield();
    this.MsgTypeControlout.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          // this.autocompLoading = true;
          // this.ref.markForCheck()
        }),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            this.ref.markForCheck();
            // this.isMsgoutLoading = true
            this.msgtypefunction(value, "Out");
          } else {
            this.msgtypelistout = [];
            return of(null);
          }
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
          }
          this.ref.markForCheck();
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );
    this.MsgTypeControlin.valueChanges
      .pipe(
        debounceTime(1000),
        // tap(() => {
        //   // this.autocompLoading = true;
        //   // this.ref.markForCheck()
        // }),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            this.ref.markForCheck();
            // this.isMsginLoading = true
            this.msgtypefunction(value, "In");
          } else {
            this.msgtypelistin = [];
            return of(null);
          }
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
          }
          this.ref.markForCheck();
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );
    this.facilityControl.valueChanges
      .pipe(
        debounceTime(1000),
        // tap(() => {
        //   // this.autocompLoading = true;
        //   // this.ref.markForCheck()
        // }),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
             this.isFacilityLoading = true

            this.ref.markForCheck();
            this.filter(value, "facility", false);
          } else {
            this.displaydeploy2 = "";
            this.FacilityNameList = [];
            return of(null);
          }
          this.selectedlabobj !== undefined && this.selectedlabobj !== null && this.selectedlabobj !== "" ? this.isFacilityLoading = false : "" ;
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
            this.isFacilityLoading = false;
          }
          this.ref.markForCheck();
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );
    // this.payinglabControl.valueChanges
    //   .pipe(
    //     debounceTime(1000),
    //     // tap(() => {
    //     //   // this.autocompLoading = true;
    //     //   // this.ref.markForCheck()
    //     // }),
    //     distinctUntilChanged(),
    //     switchMap((value: string): any => {
    //       if (value !== undefined && value !== null && value !== "") {
    //         // this.autocompLoading = true;
    //        // this.ref.markForCheck();
    //          this.isPLabLoading = true

    //         this.filter(value, "payinglab", false);
    //       } else {
    //         this.paylabNameList = [];
    //         return of(null);
    //       }
    //       this.selectedlabobj !== undefined && this.selectedlabobj !== null && this.selectedlabobj !== "" ? this.isPLabLoading = false : "" ;
    //       // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
    //     })
    //   )
    //   .subscribe(
    //     (data) => {
    //       this.ngxuiloader.stop();
    //       if (data !== null) {
    //         this.isPLabLoading = false;
    //       }
    //       //this.ref.markForCheck();
    //       if (data) {
    //         // this.payorList = data;
    //       }
    //     },
    //     (error) => {
    //       // this.autocompLoading = false;
    //       console.error();
    //     }
    //   );

    // this.labControl.valueChanges
    //   .pipe(
    //     debounceTime(1000),
    //     distinctUntilChanged(),
    //     switchMap((value: string): any => {
    //       if (value !== undefined && value !== null && value !== "") {
    //         // this.autocompLoading = true;
    //         this.isLabLoading = true;
    //        // this.ref.markForCheck();
    //         this.filter(value, "lab", false);
    //       } else {
    //         this.displaydeploy = "";
    //         this.labNameList = [];
    //         return of(null);
    //       }
    //       this.selectedlabobj !== undefined && this.selectedlabobj !== null && this.selectedlabobj !== "" ? this.isLabLoading = false : "" ;
    //       // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
    //     })
    //   )
    //   .subscribe(
    //     (data) => {
    //       this.ngxuiloader.stop();
    //       if (data !== null) {
    //         // this.isLabLoading = false;
    //       }
    //         //this.ref.markForCheck();
    //       // this.isLabLoading = false;
    //       if (data) {
    //         // this.payorList = data;
    //       }
    //     },
    //     (error) => {
    //       // this.autocompLoading = false;
    //       console.error();
    //     }
    //   );
    this.LabImportControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
             this.isLabImpLoading = true;
            this.ref.markForCheck();
            this.filter(value, "labImport", false);
          } else {
            this.displaydeployImp = "";
            this.labNameImpList = [];
            return of(null);
          }
          this.selectedlabobj !== undefined && this.selectedlabobj !== null && this.selectedlabobj !== "" ? this.isLabImpLoading = false : "" ;
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
            // this.isLabImpLoading = false;
          }
          this.ref.markForCheck();
          this.isLabImpLoading = false;
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );

    this.LabExportControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
             this.isLabExpLoading = true;
            this.ref.markForCheck();
            this.filter(value, "labExport", false);
          } else {
            this.displaydeployExp = "";
            this.labNameExpList = [];
            return of(null);
          }
          this.isLabExpLoading = false;
         // this.selectedlabobj !== undefined && this.selectedlabobj !== null && this.selectedlabobj !== "" ? this.isLabExpLoading = false : "" ;
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
            this.isLabExpLoading = false;
          }
          this.ref.markForCheck();
          
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          this.isLabExpLoading = false;
          console.error();
        }
      );

    this.processoragentControlout.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          // this.autocompLoading = true;
          // this.ref.markForCheck()
        }),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            this.ref.markForCheck();
            this.listvalidateout = true;
            this.isProcessoroutLoading = true;
            this.processorapifunctionout(value);
          } else {
            this.listvalidateout = false;
            return of(null);
          }
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
          }
          this.ref.markForCheck();
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );

    this.processoragentControlin.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          // this.autocompLoading = true;
          // this.ref.markForCheck()
        }),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            this.ref.markForCheck();
            this.isProcessorinLoading = true;
            this.listvalidatein = true;
            this.processorapifunction(value);
          } else {
            this.listvalidatein = false;
            return of(null);
          }
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
          }
          this.ref.markForCheck();
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );
 ////////////////////////////////////////////////code end/////////////////////////////////////////////////////////////////
      }


  processorapifunction(value: any) {
    this.listvalidatein = false;
    this.commonService.getproccessorapidata(value).subscribe((data) => {
      this.listvalidatein = true;
      this.isProcessorinLoading = false;
      this.processorapilistin = data;
    });
  }

  senderanddestin(id: any) {
    let obj = {
      id: id,
      type: "Processor",
    };
    this.processoridin = id;
    this.commonService.getagents(obj).subscribe((data) => {
      this.senderanddestinjsonid = data[0].id;
      this.senderanddestinjsonpipelineuniqueid = data[0].UniqueID;
      this.senderanddestinjson = JSON.parse(data[0].jsonconfig);
      this.isProcessorinLoading = false;
      this.senderagentControlin.setValue(
        this.senderanddestinjson.processoragentconfig.sourceagentid
      );
      this.destinationagentControlin.setValue(
        this.senderanddestinjson.processoragentconfig.destinationagentid
      );
    });
  }
  processoragentfunction() {
    let [contenttype, msgtype, direction] = [
      this.ConnectivityFormGroup.get("ContentType").value,
      this.MsgTypeControlin.value,
      // this.ConnectivityFormGroup.get("InterfaceType").value,
      this.ConnectivityFormGroup.get("InterfaceType").value,
    ];
    if (
      (contenttype != null || contenttype != undefined) &&
      (msgtype != null || msgtype != undefined) &&
      (direction != null || direction != undefined)
    ) {
      let obj = {
        contenttype: contenttype,
        msgtype: msgtype,
        direction: "Inbound",
      };
      this.commonService.getproccessordata(obj).subscribe((data) => {
        // this.isProcessorinLoading=false
        this.totalprocessdatain = data;
      });
    }
  }

  filter(event: any, column: any, iseditform: boolean) {
    if(this.isTabName=="Import" || this.isTabName=="Export")
    {
         event = event.replaceAll(".","");
    }
    if(event != '' && !event.endsWith(":") && !event.includes('GUID'))
    {
      this.isLoadingroutes = false;
    if (column == "lab") {
      // this.isLabLoading = true;
    }
    let obj;
      if (column == "facility") {
        obj = {
          lSearch: event,
          LocationGUID: this.selectedlabobj.LocationGUID,
          deploymentkey: this.selectedlabobj.deploymentkey,
          associatedaccountids: this.selectedlabobj.associatedaccountids,
        };
      }
      else {
        obj = {
          lSearch: event
        }
      }
   // this.commonService.getEntityfilterdata(event).subscribe((data) => {

      this.commonService
      .GetAccountNamesListAsync(obj)
      .subscribe((data: any) => {
        if (column == "facility") {
          this.isLoadingroutes = true;
          this.Facilityselected.setValue(event);
          if (data.length == 0) {
            this.FacilityNameList = [];
            this.isFacilityLoading = false;
  
            this.notfounddata = true;
          } else {
            this.FacilityNameList = [];
            this.isFacilityLoading = false;
            this.FacilityNameList = data;
            this.notfounddata = false;
            if (iseditform) {
              this.Assignlabdeloyment();
            }
          }
        }
        if (column == "lab") {
          this.selectedvalue = event;
          this.Labselected.setValue(event);
          if (data.length == 0) {
            this.labNameList = [];
            this.isLabLoading = false;
            this.notfounddata1 = true;
          } else {
            this.labNameList = [];
            this.isLabLoading = false;
            this.labNameList = data;
            this.notfounddata1 = false;
            if (iseditform) {
              this.Assignlabdeloyment();
            }
          }
          // return this.labNameList
        }
        if (column == "payinglab") {
          this.selectedpaylabvalue = event;
          this.payLabselected.setValue(event);
          if (data.length == 0) {
            this.paylabNameList = [];
            this.isPLabLoading = false;
            this.notfounddata2 = true;
          } else {
            this.paylabNameList = [];
            this.isPLabLoading = false;
            this.paylabNameList = data;
            this.notfounddata2 = false;
          }
        }
        if (column == "labImport") {
          this.selectedLabImpvalue = event;
          this.LabImpselected.setValue(event);
          if (data.length == 0) {
            this.labNameImpList = [];
            this.isLabImpLoading = false;
            this.notfounddataImp = true;
          } else {
            this.labNameImpList = [];
            this.isLabImpLoading = false;
            this.labNameImpList = data;
            this.notfounddataImp = false;
            this.selectedlabImpobj = this.labNameImpList.find( V=> V.LocationGUID === this.isEditLabImpGUID && V.deploymentkey === this.ImpDeploymentKey )
            if (iseditform) {
              this.Assignlabdeloyment();
            }
          }
          // return this.labNameList
        }
        if (column == "labExport") {
          this.selectedLabExpvalue = event;
          this.LabExpselected.setValue(event);
          if (data.length == 0) {
            this.labNameExpList = [];
            this.isLabExpLoading = false;
            this.notfounddataExp = true;
          } else {
            this.labNameExpList = [];
            this.isLabExpLoading = true;
            this.labNameExpList = data;
            this.notfounddataExp = false;
            this.selectedlabExpobj = this.labNameExpList.find( V=> V.LocationGUID === this.isEditLabExpGUID && V.deploymentkey === this.ExpDeploymentKey )
            if (iseditform) {
              this.Assignlabdeloyment();
            }
          }
          // return this.labNameList
        }
  
      });
    }
      
      else
      {
        this.notfounddata = true;
        this.notfounddata1 = true;
        this.notfounddata2 = true;
        this.notfounddataImp = true;
        this.notfounddataExp = true;
        this.isLabLoading = false;
        this.isLabImpLoading = false;
        this.isLabImportLoading=false;
        this.isLabExpLoading=false;
        this.isFacilityLoading=false;
        this.isPLabLoading=false;
      }
    
  }
  LabKeyClearFunc(event) {
    if (event.target.value =='') {
      this.selectedlabobj.LocationGUID = '';
      this.selectedlabobj.deploymentkey = '';
      this.selectedlabobj.associatedaccountids = ''; 
      this.displaydeploy = "";
        this.labNameList = [];	  
    }
	else if (event.target.value !== undefined && event.target.value !== null && event.target.value !== "") {
        this.isLabLoading = true;
        //this.ref.markForCheck();
        this.filter(event.target.value, "lab", false);
		this.selectedlabobj !== undefined && this.selectedlabobj !== null && this.selectedlabobj !== "" 
        ? this.isLabLoading = false 
        : "";
      } 
     }

     payinglabValueChange(event){
      if (event.target.value =='') {
        this.paylabNameList = [];
      }
	  else if (event.target.value !== undefined && event.target.value !== null && event.target.value !== "") {
            
             this.isPLabLoading = true

            this.filter(event.target.value, "payinglab", false);
          }
	  this.selectedlabobj !== undefined && this.selectedlabobj !== null && this.selectedlabobj !== "" ? this.isPLabLoading = false : "" ;
          
     }

  Assignlabdeloyment() {
    if (this.isEditformsData.length > 0 && this.labNameList.length > 0) {
      this.selectedlabobj = this.labNameList.filter(
        (V) => V.LocationGUID === this.isEditformsData.labguid
      );
      if (this.selectedlabobj.length > 0) {
        this.displaydeploy = this.selectedlabobj.deploymentkey + "(L)";
      }
      this.deploy.setValue(this.displaydeploy + " , " + this.displaydeploy2);
    }
    if (this.isEditformsData.length > 0 && this.FacilityNameList.length > 0) {
      this.selectedfacilityobj = this.FacilityNameList.filter(
        (V) => V.LocationGUID === this.isEditformsData.facilityguid
      );
      if (this.selectedfacilityobj.length > 0) {
        this.displaydeploy2 = this.selectedfacilityobj.deploymentkey + "(F)";
      }
      this.deploy.setValue(this.displaydeploy + "  " + this.displaydeploy2);
    }
    if (this.isEditformsData.length > 0 && this.labNameImpList.length > 0) {
      this.selectedlabImpobj = this.labNameImpList.filter(
        (V) => V.LocationGUID === this.isEditformsData.labguid
      );
      if (this.selectedlabImpobj.length > 0) {
        this.displaydeployImp = this.selectedlabImpobj.deploymentkey + "(L)";
      }
      this.deploy.setValue(this.displaydeployImp);
    }
    if (this.isEditformsData.length > 0 && this.labNameExpList.length > 0) {
      this.selectedlabExpobj = this.labNameExpList.filter(
        (V) => V.LocationGUID === this.isEditformsData.labguid
      );
      if (this.selectedlabExpobj.length > 0) {
        this.displaydeployExp = this.selectedlabExpobj.deploymentkey + "(L)";
      }
      this.deploy.setValue(this.displaydeployExp);
    }
  }

  filterbyguid(guid: any, field: any) {
    if(field == "Device"){
      this.selecteddevicename=guid;
      let selectedevice=this.DeviceList.find(device=>device.DeviceName === this.selecteddevicename);
      if(selectedevice){
        this.selectedmessageversion=selectedevice.MessageVersion;
        this.selectedMessageFormat=selectedevice.MessageFormat;
        this.selectedDeviceType=selectedevice.DeviceType;
        this.selectedConnectivityType=selectedevice.ConnectivityType;
        this.selectedBufferSize=selectedevice.BufferSize;
        this.selectedSendReflexTestOrder=selectedevice.SendReflexTestOrder;
        this.selectedReflexDeviceId=selectedevice.ReflexDeviceId;
        this.selectedReflexDeviceName=selectedevice.ReflexDeviceName;
        this.selectedCheckSumType=selectedevice.CheckSumType;
        this.ModelInfo=selectedevice.ModelInfo;
        this.ContType=[];
        this.ContType.push({ lookupid: this.selectedMessageFormat, lookupvalue: this.selectedMessageFormat });
      }
      
      this.DeviceFormGroup.patchValue({
        DeviceName:this.selecteddevicename
      });
    }else{
    let selecteguid,deployment
    if(guid !== ''){
       selecteguid= guid.split(":")[1]
      selecteguid = selecteguid.split(")")[0]
   deployment= guid.split("(")[2]
  deployment= deployment.split(")")[0]
    }
    if (field == "lab") {

      this.isLabLoading = false;

      this.selectedlabobj = this.labNameList.find( V=> V.LocationGUID === selecteguid && V.deploymentkey === deployment )
      let obj = {
        ...this.selectedlabobj,
        modifierid: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
          .userdata["userid"],
      };
      this.displaydeploy = this.selectedlabobj.deploymentkey + "(L)";
    }
    if (field == "payinglab") {

      this.isPLabLoading = false;
      this.selectedpaylabobj = this.paylabNameList.find( V=> V.LocationGUID === selecteguid  && V.deploymentkey === deployment )
      // this.displaydeploy = this.selectedpaylabobj[0].deploymentkey + "(PL)";
    }
    if (field == "facility") {

      this.isFacilityLoading = false;
      this.selectedfacilityobj = this.FacilityNameList.find( V=> V.LocationGUID === selecteguid  && V.deploymentkey === deployment )
      this.displaydeploy2 = this.selectedfacilityobj.deploymentkey + "(F)";
      this.facaddr1 = this.selectedfacilityobj.Address1 !== '' || this.selectedfacilityobj.Address1 !== null ? this.selectedfacilityobj.Address1 : this.selectedfacilityobj.Address2

      this.FacilityFormGroup.patchValue({
        facilityaddress:this.facaddr1,
      });
      // this.facaddr2 = this.selectedfacilityobj.Address2 !== '' || this.selectedfacilityobj.Address2 !== null ? this.selectedfacilityobj.Address2 : this.facilityAddressControl.value
    }

    this.deploy.setValue(this.displaydeploy + "  " + this.displaydeploy2);

    if (field == "labImport") {
      this.isLabImpLoading = false;
      this.selectedlabImpobj = this.labNameImpList.find(
        (V) => V.LocationGUID === selecteguid  && V.deploymentkey === deployment
      );
      let obj = {
        ...this.selectedlabImpobj,
        modifierid: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
          .userdata["userid"],
      };
      this.displaydeployImp = this.selectedlabImpobj.deploymentkey + "(L)";
    }

    if (field == "labExport") {
      this.isLabExpLoading = false;
      this.selectedlabExpobj = this.labNameExpList.find(
        (V) => V.LocationGUID === selecteguid && V.deploymentkey === deployment
      );
      let obj = {
        ...this.selectedlabExpobj,
        modifierid: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
          .userdata["userid"],
      };
      this.displaydeployExp = this.selectedlabExpobj.deploymentkey + "(L)";
    }
  }
  }

  onAgentSelected(agentName: string) {
    this.selectedvbagent = agentName;
    const selectedAgent = this.vbagentlist.find(agent => agent.AgentName === this.selectedvbagent);
    
    if (selectedAgent) {
      this.selectedVBAgentId = selectedAgent.AgentID;
      this.selectedVBIPAddress = selectedAgent.IPAddress;
      // Patch the selected AgentName back into the form (if needed)
      this.DeviceFormGroup.patchValue({
        VBAgent: this.selectedvbagent
      });
    }
  }

  onJobSubTypeSelected(JobSubType: string) {
    this.selectedJobSubType = JobSubType;
    this.DeviceFormGroup.patchValue({
      JobSubType: this.selectedJobSubType
    });
       
    if (this.selectedJobSubType === 'ResultOnly' && this.isdevice) {
      this.interfaceTypes = this.interfaceTypes.filter(type => type.interfaceType !== 'Instrument Order Outbound');
    }
    else {
      this.interfaceTypes=this.backupInterfacetype;
    }
    
  }

  msgtypefunction(value: any, direction: any) {
    this.commonService.getMsgtypedata().subscribe((data) => {
      if (direction == "In") {
        this.isMsginLoading = false;
        this.msgtypelistin = data.filter((V) => V.includes(value));
        return this.msgtypelistin;
      }
      if (direction == "Out") {
        this.isMsgoutLoading = false;
        this.msgtypelistout = data.filter((V) => V.includes(value));
        return this.msgtypelistout;
      }
    });
  }

  processorapifunctionout(value: any) {
    this.listvalidateout = false;
    this.commonService.getproccessorapidata(value).subscribe((data) => {
      this.listvalidateout = true;
      this.isProcessoroutLoading = false;
      this.processorapilistout = data;
    });
  }

  senderanddestout(id: any) {
    let obj = {
      id: id,
      type: "Processor",
    };
    this.processoridout = id;
    this.commonService.getagents(obj).subscribe((data) => {
      this.senderanddestoutjsonid = data[0].id;
      this.senderanddestoutjsonpipelineuniqueid = data[0].UniqueID;
      this.senderanddestoutjson = JSON.parse(data[0].jsonconfig);
      this.isProcessoroutLoading = false;
      this.senderagentControlout.setValue(
        this.senderanddestoutjson.processoragentconfig.sourceagentid
      );
      this.destinationagentControlout.setValue(
        this.senderanddestoutjson.processoragentconfig.destinationagentid
      );
    });
  }

 async interfacebtwselected() {
    if (this.LabFormGroup.get("interfaceBetween").value == "Custom") {
      this.flag = true;
      this.selectInttype();
      // this.LabFormGroup.patchValue({interfaceBetween:''})
    }
    else if(this.LabFormGroup.get("interfaceBetween").value == "Between Lab and Device"){
      this.isdevice=true;
      this.flag = false;
      let dl=await this.getDevicelist();
      this.selectInttype();
      let aglst=await this.Getvbagentsdetails();
      this.Ctypes.push({ value: "Device", viewValue: "Device" });
      // Filter vendors to include only those related to 'Device'
    const filteredVendorArray = this.VendorsList.filter(vendor => vendor.name === 'Device');
    this.filteredVendor = of(filteredVendorArray); // Convert array to Observable
    }else {
      this.flag = false;
      this.isdevice=false;
      this.DeviceList=[];
      this.vbagentlist=[];
      this.filteredVendor = of(this.VendorsList.slice()); // Reset to original vendor list as an Observable
    }
  }
  interfacescopeselected() {
    if (this.LabFormGroup.get("interfaceScope").value == "Other") {
      this.flag2 = true;
      // this.LabFormGroup.patchValue({interfaceScope:''})
    } else {
      this.flag2 = false;
    }
  }

  processoragentfunctionout() {
    let [contenttype, msgtype, direction] = [
      this.ConnectivityFormGroup.get("ContentTypeOutbound").value,
      this.MsgTypeControlout.value,
      this.ConnectivityFormGroup.get("InterfaceType").value,
    ];
    if (
      (contenttype != null || contenttype != undefined) &&
      (msgtype != null || msgtype != undefined) &&
      (direction != null || direction != undefined)
    ) {
      let obj = {
        contenttype: contenttype,
        msgtype: msgtype,
        direction: "Outbound",
      };
      this.commonService.getproccessordata(obj).subscribe((data) => {
        this.totalprocessdataout = data;
      });
    }
  }

  resetRequestForm() {

this.approvalStatus=''
if(this.pagerolepermisions.ApproveAndreject)
{
  this.showProjectSubmitButton=true;
 this.ShowApproveAndRejectButton=false
}
else{
  this.showapprovebutton=true;
}
this.ImpID=0
this.ExpID=0
this.sendmail =true
this.ShowsaveButton=true
    this.isdevice=false;
    this.projImpID = '';
    this.projExpID = '';
    this.projid = '';
    this.LabFormGroup.reset();
    this.payinglabControl.reset();
    this.FacilityFormGroup.reset();
    this.facaddr1 ='';
    this.requesterFormGroup.reset();
    this.VendorFormGroup.reset();
    this.DetailsFormGroup.reset();
    this.ConnectivityFormGroup.reset();
    this.labControl.reset();
    this.payingLabCtrl.reset();
    this.destinationagentControlout.reset();
    this.senderagentControlout.reset();
    this.processoragentControlout.reset();
    this.MsgTypeControlout.reset();
    this.destinationagentControlin.reset();
    this.senderagentControlin.reset();
    this.processoragentControlin.reset();
    this.MsgTypeControlin.reset();
    this.deploy.reset();
    this.Labselected.reset();
    this.Facilityselected.reset();
    this.facilityControl.reset();
    this.facilityAddressControl.reset();
    this.Labs = [];
    this.payingLabs = [];
    this.isedit = false;
    this.vendorNameControl.reset();
    this.vendorNameImpControl.reset();
    this.vendorNameExpControl.reset();
    this.resetExportForm();
    this.resetImportForm();
    this.projFiles=[];
    this.selectedfacilityobj='';
    this.selectedlabobj="";
    (this.LabFormGroup.get("labContactdetails") as FormArray).clear();
    (this.FacilityFormGroup.get("facilityContactdetails") as FormArray).clear();
    (this.VendorFormGroup.get("vendorContactdetails") as FormArray).clear();
    (this.ConnectivityFormGroup.get("ProjInterfacecard") as FormArray).clear();
    this.DeviceFormGroup.reset();
    this.DeviceList=[];
  }

  resetImportForm() {
    this.LabFormGroupImport.reset();
    this.LabImportControl.reset();
    this.requesterFormGroupImport.reset();
    this.VendorFormGroupImport.reset();
    this.projectFormGroupImport.reset();
    (this.LabFormGroupImport.get("labContactdetailsImport") as FormArray).clear();
  }

  resetExportForm() {
    this.LabFormGroupExport.reset();
    this.LabExportControl.reset();
    this.requesterFormGroupExport.reset();
    this.VendorFormGroupExport.reset();
    this.projectFormGroupExport.reset();
    (
      this.LabFormGroupExport.get("labContactdetailsExport") as FormArray
    ).clear();
  }

  async submitImportForm(isSubmitForms: boolean) {
    this.ShowApproveAndRejectButton=false;
    if (this.selectedlabImpobj !== undefined) {
      this.LabFormGroupImport.patchValue({ LabImport: this.selectedlabImpobj.Displayname });
    }
    let contactDetailsImp = [];
    if (this.LabFormGroupImport.value.labContactdetailsImport.length > 0) {
      let obs = {
        lab: this.LabFormGroupImport.value.labContactdetailsImport,
      };
      contactDetailsImp.push(obs);
    }
    if (this.VendorFormGroupImport.value.vendorImpContactdetails.length > 0) {
      let obs = {
        vendor: this.VendorFormGroupImport.value.vendorImpContactdetails,
      };
      contactDetailsImp.push(obs);
    }
    let obs = {
      requester: [
        {
          name: this.requesterFormGroupImport.value.requesterImpName,
          email: this.requesterFormGroupImport.value.requesterImpEmail,
          phone: this.requesterFormGroupImport.value.requesterImpPhone,
          id: this.reqImpId !== undefined ? this.reqImpId : this.reqNoID
        },
      ],
    };
    contactDetailsImp.push(obs);

    let detailImp = JSON.stringify(contactDetailsImp);

    this.venMnemonicImp

    let importObj= this.objectForImportAndExport(detailImp,this.selectedlabImpobj,this.requesterFormGroupImport.value.requesterImpName,this.projectFormGroupImport.value.projectImpName,this.projectFormGroupImport.value.projectImpScope,this.VendorFormGroupImport.value.ApplicationNameImp, this.VendorFormGroupImport.value.ApplicationVersionImp,this.vendoIDImp,this.venMnemonicImp,"Import",this.ImpID,this.projImpID,this.LabFormGroupImport.value.lab)

    if((isSubmitForms===false && this.approvalStatus=="Get Approval")||isSubmitForms === true)
     {
      if (this.LabFormGroupImport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.LabFormGroupImport.controls.labContactdetailsImport.status == "INVALID") {
        this.toastr.warning("Invalid contact details", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.requesterFormGroupImport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Requester Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.projectFormGroupImport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Project Details Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (
        this.LabFormGroupImport.status != "INVALID" &&
        this.requesterFormGroupImport.status != "INVALID" &&
        this.projectFormGroupImport.status !== "INVALID"
      ) {
        importObj.isSubmit = true;
        importObj.vendorName = this.vendoNameImp
          // obj.interfaces = config
        if(isSubmitForms===false&& this.approvalStatus=="Get Approval")
        {
           this.saveorsubmitformapi(importObj,'Import','save');
        }
        else{
           this.saveorsubmitformapi(importObj,'Import','submit');
        }
      }
    }

    else {
      this.saveorsubmitformapi(importObj,'Import','save');
    }
  }

  async submitExportForm(isSubmitForms: boolean) {
    if (this.selectedlabExpobj !== undefined) {
      this.LabFormGroupExport.patchValue({ LabExport: this.selectedlabExpobj.Displayname });
    }

    let contactDetailsExp = [];
    if (this.LabFormGroupExport.value.labContactdetailsExport.length > 0) {
      let obs = {
        lab: this.LabFormGroupExport.value.labContactdetailsExport,
      };
      contactDetailsExp.push(obs);
    }
    let obs = {
      requester: [
        {
          name: this.requesterFormGroupExport.value.requesterExpName,
          email: this.requesterFormGroupExport.value.requesterExpEmail,
          phone: this.requesterFormGroupExport.value.requesterExpPhone,
          id: this.reqID !== undefined ? this.reqID : this.reqNoID
        },
      ],
    };
    contactDetailsExp.push(obs);

    let detailExp = JSON.stringify(contactDetailsExp);
  let ExportObj=this.objectForImportAndExport(detailExp,this.selectedlabExpobj,this.requesterFormGroupExport.value.requesterExpName,this.projectFormGroupExport.value.projectExpName,this.projectFormGroupExport.value.projectExpScope,this.VendorFormGroupExport.value.ApplicationNameExp,this.VendorFormGroupExport.value.ApplicationVersionExp,this.vendoIDExp,this.venMnemonicImp,"Export",this.ExpID,this.projExpID,this.LabFormGroupExport.value.lab)



  if(isSubmitForms===false && this.approvalStatus=="Get Approval"||isSubmitForms === true) {
      if (this.LabFormGroupExport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.LabFormGroupExport.controls.labContactdetailsExport.status == "INVALID") {
        this.toastr.warning("Invalid contact details", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.requesterFormGroupExport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Requester Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.projectFormGroupExport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Project Details Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (
        this.LabFormGroupExport.status != "INVALID" &&
        this.requesterFormGroupExport.status != "INVALID" &&
        this.projectFormGroupExport.status !== "INVALID"
      ) {
        ExportObj.isSubmit = true;
        ExportObj.vendorName = this.vendoNameExp
        // obj.interfaces = config
        if(isSubmitForms===false&& this.approvalStatus=="Get Approval")
        {
           this.saveorsubmitformapi(ExportObj,'Export','save');
        }
        else{
           this.saveorsubmitformapi(ExportObj,'Export','submit');
        }
      }
      }
    else {
      this.saveorsubmitformapi(ExportObj,'Export','save');
    }
  }

async saveorsubmitformapi(obj,isform:string,isaction:string){
    if(isaction=='submit'){
      this.ngxuiloader.start();
      await  this.commonService.submitInterfaceForm(obj).subscribe(async (data) => {
         

          if (data.status == "Success") {
            this.ngxuiloader.stop();
            let resmsg=isform=='Import'?data.responsebody:isform=='Export'?data.statusmessage:data.ProjectID;
            this.toastr.success(resmsg,"Submitted Successfully:",  {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });

            if (isform == 'Import') {
              if (this.sendmail) {
                 this.sendprojectchecklist(data.Attributes);
              }
                 this.SaveNotes(data.Attributes,this.approvalStatus)

            }
           else if(isform == 'Export')
           {
              if (this.sendmail) {
                 this.sendExportProjectCheckist(data.Attributes);

              }
              this.SaveNotes(data.Attributes,this.approvalStatus)
            }
            this.closeCreateProject()
            // this.isShowCard = false;
            isform=='Import'?this.ShowApproveAndRejectButton=false:'';

          }
		
        else {
          this.ngxuiloader.stop();
          this.toastr.error("Failed to Submit", data.statusmessage, {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
          

          }
        });
    }else if(isaction=='save'){
      this.ngxuiloader.start();
      this.commonService.saveInterfaceForm(obj).subscribe(async (data) => {
        if(data)
        if (isform == 'Import') {
          if (this.sendmail) {
             this.sendprojectchecklist(data.Attributes);
          }
          if(this.approvalStatus=="Get Approval")
          {
             this.SaveNotes(data.Attributes,this.approvalStatus)
          }
        }
        else if(isform == 'Export')
        {
           if (this.sendmail) {
              this.sendExportProjectCheckist(data.Attributes);
           }
           if(this.approvalStatus=="Get Approval")
           {
              this.SaveNotes(data.Attributes,this.approvalStatus)
           }

        }


        this.ngxuiloader.stop();
        if (data.status == "Failure") {
          this.toastr.error("Failed to Save", data.statusmessage, {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
        else {
          this.toastr.success(data.statusmessage, "", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
          this.interfaceFormIdResponse = data.responsebody;
          isform=='Interface'?'':this.isShowCard = false;
          // this.resetRequestForm();
          // this.getInterfaceFormList("Import");

        }
        setTimeout(() => {
          this.closeCreateProject()
        }, 3000);
      });
    }
  }



  addtoAddress(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (this.isEmail(value.trim())) {
      if ((value || '').trim()) {
        this.toaddresslist.push({ toAddressid: value.trim() });
        this.toaddressctrl.setValue('')
        this.tomailInput.nativeElement.value=null
      }
    }
    this.toaddressctrl.setValue(null);
  }

  isEmail(search: string) {
    let serchfind;
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    serchfind = regexp.test(search);
    return serchfind
  }

  removetoAddress(tolist: any): void {
    const index = this.toaddresslist.indexOf(tolist);

    if (index >= 0) {
      this.toaddresslist.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    let obj = {
      toAddressid: event.option.viewValue
    }
    let isccid=this.Cclist.find(X=>X.Ccid===event.option.viewValue);
    if(this.toaddresslist.length>0){
      let isexist=this.toaddresslist.filter(V=>V.toAddressid===event.option.viewValue)
      if(isexist.length==0 &&(isccid==undefined || isccid==null))
    {
      this.toaddresslist.push(obj);
    }
  }
  else{
    if(isccid==undefined || isccid==null)
    {
      this.toaddresslist.push(obj);
    }
  }
    if (this.tomailInput !== undefined) {
      this.tomailInput.nativeElement.value = '';
    }
    this.toaddressctrl.setValue(null);
    // if(this.tomailInput){
    //   this.tomailInput.nativeElement.blur();
    // }
  }

  private _filtertoaddress(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.alltoaddressid.filter(V => V.toAddressid.toLowerCase().includes(filterValue));

  }
  /////////////////////////////////////////Ccmatchip



  addCc(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (this.isEmail(value.trim())) {
      if ((value || '').trim()) {
        this.Cclist.push({ Ccid: value.trim() });
        this.Cctrl.setValue('')
        this.CcInput.nativeElement.value=null
      }
    }
    this.Cctrl.setValue(null);
  }

  removeCc(tolist: any): void {
    const index = this.Cclist.indexOf(tolist);

    if (index >= 0) {
      this.Cclist.splice(index, 1);
    }
  }

  selectedCc(event: MatAutocompleteSelectedEvent): void {
    let obj = {
      Ccid: event.option.viewValue
    }
    let istoid=this.toaddresslist.find(V=>V.toAddressid===event.option.viewValue);
    if(this.Cclist.length>0){
      let isexist=this.Cclist.filter(V=>V.Ccid===event.option.viewValue)
      if(isexist.length==0 &&(istoid==undefined || istoid==null))
    {
      this.Cclist.push(obj);
    }
  }else{
    if(istoid==undefined || istoid==null)
    {
      this.Cclist.push(obj);
    }
  }
    if (this.CcInput !== undefined) {
      this.CcInput.nativeElement.value = '';
    }
    this.Cctrl.setValue(null);
    if(this.CcInput){
      this.CcInput.nativeElement.blur();
    }
  }

  private _filterCc(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCcid.filter(V => V.Ccid.toLowerCase().includes(filterValue));
  }
  downloadfile()
  {
    if(this.isftpmail)
    {
      const source = this.ftpbase64;
      const link = document.createElement("a");
      link.href = source;
      link.download = this.filenamedisplay;
      link.click();
    }
    else{
      const source = this.excelbase64;
          const link = document.createElement("a");
          link.href = source;
          link.download = this.base64content['FileDownloadName'];
          link.click();
    }
  }

  listaddress(isproject:string){
    this.alltoaddressid=[];
    this.allCcid=[];
    if(isproject=='import')
    {
      this.viewVendorImp.forEach(X=>{
        if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
          let toobj={
            "toAddressid":X.emailid
          }
          this.alltoaddressid.push(toobj)
        }
        })

        this.viewImpReq.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
          {
            let toobj={
              "toAddressid":X.emailid
            }
            this.alltoaddressid.push(toobj)
          }})

          this.viewImpLab.forEach(X=>{
            if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
            {
              let toobj={
                "toAddressid":X.emailid
              }
              this.alltoaddressid.push(toobj)
            }})

        this.viewVendorImp.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
            {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}
        })
          this.viewImpReq.forEach(X=>{
            if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
            {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}})
            this.viewImpLab.forEach(X=>{
              if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
              {
            let ccobj={
              "Ccid":X.emailid
            }
            this.allCcid.push(ccobj)}})
    }
    else if(isproject=='interface'){
      this.viewlabCont.forEach(X=>{
        if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
        let toobj={
          "toAddressid":X.emailid
        }
        this.alltoaddressid.push(toobj)}})
        this.viewReqCont.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
          {
          let toobj={
            "toAddressid":X.emailid
          }
          this.alltoaddressid.push(toobj)}})
          this.viewFacCont.forEach(X=>{
            if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
            {
            let toobj={
              "toAddressid":X.emailid
            }
            this.alltoaddressid.push(toobj)}})
      this.viewVenCont.forEach(X=>{
        if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
        let toobj={
          "toAddressid":X.emailid
        }
        this.alltoaddressid.push(toobj)}})

        this.viewlabCont.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}})

          this.viewReqCont.forEach(X=>{
            if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
            let ccobj={
              "Ccid":X.emailid
            }
            this.allCcid.push(ccobj)}})

            this.viewFacCont.forEach(X=>{
              if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
              let ccobj={
                "Ccid":X.emailid
              }
              this.allCcid.push(ccobj)}})

        this.viewVenCont.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}})
    }
    else if(isproject=='export'){
      this.viewexpLab.forEach(X=>{
        if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
        let toobj={
          "toAddressid":X.emailid
        }
        this.alltoaddressid.push(toobj)}})
        this.viewexpReq.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
          {
          let toobj={
            "toAddressid":X.emailid
          }
          this.alltoaddressid.push(toobj)}})

      this.viewVendorexp.forEach(X=>{
        if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
        let toobj={
          "toAddressid":X.emailid
        }
        this.alltoaddressid.push(toobj)}})

        this.viewexpLab.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}})

          this.viewexpReq.forEach(X=>{
            if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
            let ccobj={
              "Ccid":X.emailid
            }
            this.allCcid.push(ccobj)}})

        this.viewVendorexp.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}})
    }

  }
  ftpemailpop(value:boolean,isproject:string)
{
 this.listaddress(isproject);
  this.ngxuiloader.start();
this.isftpmail=value;
this.mainSubject.reset();
let name,id
if(isproject=='import'){
  id=this.viewImport['projectID']
  name=this.viewImport['projectName']
}
else if(isproject=='export'){
  id=this.viewexport['projectID']
  name=this.viewexport['projectName']
}
else{
id=this.viewform['projectID']
name=this.viewform['projectName']
}
  this.mainSubject.setValue('FTP Details for Project :'+name+"("+id+")");

this.htmlContent=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,</span></span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Please find the attached file for FTP Information. </span></span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:10.0000pt"><span style="font-family:Calibri">Note: Attached file is password protected for the security purposes. Please refer to the following email to locate the password.</span></span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>Thanks &amp; Regards</strong></span></span></strong></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>VitalAxis</strong></span></span></strong></span></span></p>`;
let obj
if(isproject=='import'){
if(this.viewImport['projectVaFtpDetails'] !== undefined){
  obj=
  {
    "projectID":this.viewImport['projectID'],
    "projectname":this.viewImport['projectName'],
    "interfaceID":"",
    "interfacename":"",
    "ftpdata":{
      "Host":this.viewImport['projectVaFtpDetails']['Host'],
      "Username":this.viewImport['projectVaFtpDetails']['Username'],
      "Password":this.viewImport['projectVaFtpDetails']['Password'],
      "Port":this.viewImport['projectVaFtpDetails']['Port'],
      "Remotepath":this.viewImport['projectVaFtpDetails']['Remotepath']
    }
  }
}else{
  obj={}
}
}else if(isproject=='export'){
  if(this.viewexport['projectVaFtpDetails'] !== undefined){
    obj=
    {
      "projectID":this.viewexport['projectID'],
      "projectname":this.viewexport['projectName'],
      "interfaceID":"",
      "interfacename":"",
      "ftpdata":{
        "Host":this.viewexport['projectVaFtpDetails']['Host'],
        "Username":this.viewexport['projectVaFtpDetails']['Username'],
        "Password":this.viewexport['projectVaFtpDetails']['Password'],
        "Port":this.viewexport['projectVaFtpDetails']['Port'],
        "Remotepath":this.viewexport['projectVaFtpDetails']['Remotepath']
      }
    }
  }else{
    obj={}
  }
}
else{
  if(this.viewform['projectVaFtpDetails'] !== undefined){
    obj=
    {
      "projectID":this.viewform['projectID'],
      "projectname":this.viewform['projectName'],
      "interfaceID":"",
      "interfacename":"",
      "ftpdata":{
        "Host":this.viewform['projectVaFtpDetails']['Host'],
        "Username":this.viewform['projectVaFtpDetails']['Username'],
        "Password":this.viewform['projectVaFtpDetails']['Password'],
        "Port":this.viewform['projectVaFtpDetails']['Port'],
        "Remotepath":this.viewform['projectVaFtpDetails']['Remotepath']
      }
    }
  }else{
    obj={}
  }
}
  this.commonService.pdf64(obj).subscribe((data) =>{
    if(data['status'].toLowerCase()=='success')
    {
      this.pdf64responsebody=data['responsebody']
      this.ftpbase64='data:application/pdf;base64,'+data['responsebody'];
      this.filenamedisplay=data['statusmessage'].split(':')[1];
      this.key=data['extendedattributes']['key'];
      this.ngxuiloader.stop();
      let authhtml
      if(this.isftpmail)
      {
        authhtml=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,<br />FTP file password enclosed below.</span></span></span></span></p>
        <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">`+this.key+`</span></span></span></span></p>
        <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Thanks &amp; Regards<br />
        VitalAxis</span></span></span></span></p>`
        this.Subject.setValue("Password to access FTP Details for "+name+"("+id+")")
      }
      else{
        authhtml=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,<br />Compendium file password enclosed below.</span></span></span></span></p>
        <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">`+this.key+`</span></span></span></span></p>
        <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Thanks &amp; Regards<br />
        VitalAxis</span></span></span></span></p>`
        this.Subject.setValue("Password to access compendium Details for "+name+"("+id+")")
      }
      let attachmentobjftp={}
      attachmentobjftp[this.filenamedisplay]=this.pdf64responsebody
      let pdfMailComponent={
        responsebody:this.pdf64responsebody,
        base64:this.ftpbase64,
        filenamedisplay:this.filenamedisplay,
        key:this.key,
        toaddresslist:this.alltoaddressid,
        Cclist:this.allCcid ,
        mainSubject:this.mainSubject.value,
        htmlContent:this.htmlContent,
        authhtml:authhtml,
        Subject:this.Subject.value,
        attachmentobj:attachmentobjftp
      }

      const modalRef = this.modalService.open(MailComponent,
        { windowClass: "myCustomModalClassFTP", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = pdfMailComponent;
      modalRef.result.then(() => { });
    }else if(data['status'].toLowerCase() ==="failure"){
      this.ngxuiloader.stop();
      this.toastr.error(data.statusmessage,'Failed', {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
  },error=>{
    this.ngxuiloader.stop();
  })
}

getsourcedetails(value:boolean,isdownload:boolean,isproject:string){
  this.listaddress(isproject);
  let obj;
  let id,depkey;
  if(isproject=='import'){
    id=this.viewImport['sourceGuid']
    depkey=this.viewImport['sourceDeploymentKey']
  }
else if(isproject=='export'){
  id=this.viewexport['sourceGuid']
  depkey=this.viewexport['sourceDeploymentKey']
}else{
  id=this.viewform['sourceGuid']
  depkey=this.viewform['sourceDeploymentKey']
}
this.ngxuiloader.start();
  this.commonService.getEntityfilterdata(id).subscribe((data) => {
    if(data!=undefined||data!=null || data.length>0 ){
    this.sourcedetails=data.filter(X=> X.deploymentkey === depkey );
    if(this.sourcedetails.length>0)
    {
      obj={
        "GUID":id,
        "Type":this.sourcedetails[0]['TypeName'],
        "CaseType":"all",
        "OptionalAOE":"1",
        "Deploymentkey":depkey
      }
      this.compendiumfilecontent(value,isdownload,obj)
    }else{
      this.ngxuiloader.stop();
      this.toastr.error("Deployment key mismatch",'',{
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }

  }else{
    this.ngxuiloader.stop();
  }
  },error=>{
    this.ngxuiloader.stop();
  })
}
compendiumfilecontent(value:boolean,isdownload:boolean,obj)
{

  this.isftpmail=value;
 this.htmlContent=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,</span></span></span></span></p>
 <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Please find the attached file for compendium. </span></span></span></span></p>
 <p>&nbsp;</p>
 <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:10.0000pt"><span style="font-family:Calibri">Note: Attached file is password protected for the security purposes. Please refer to the following email to locate the password.</span></span></span></span></p>
 <p>&nbsp;</p>
 <p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>Thanks &amp; Regards</strong></span></span></strong></span></span></p>
 <p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>VitalAxis</strong></span></span></strong></span></span></p>
 <p>&nbsp;</p>`;

 this.commonService.getcompendium(obj).subscribe((data) =>{
   if(data['status'].toLowerCase()=='success')
   {
     this.base64content= JSON.parse(data['responsebody']);
     if(this.base64content['FileContents']!=''){
     if(isdownload)
     {
       this.excelbase64='data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+this.base64content['FileContents'];
       this.ngxuiloader.stop();
       this.downloadfile();
     }
     else{
       this.compendiumemailpop(value)
     }
    }else{
      this.ngxuiloader.stop();
      this.toastr.error("Error occured getting compendium details",'',{
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
       return ""
    }
   }
   else{
    this.ngxuiloader.stop();
    this.toastr.error("Error occured",'',{
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    })
     return ""
   }
 },error=>{
  this.ngxuiloader.stop();
 })
}

compendiumemailpop(value:boolean)
{
if(this.base64content!="")
{
  this.isftpmail=value;
  let id,pname
  if(this.isproject=='import'){
    id=this.viewImport['projectID']
    pname=this.viewImport['projectName']
  }
else{
  id=this.viewform['projectID']
  pname=this.viewform['projectName']
}
  let obj={
    "FileContents":this.base64content['FileContents'],
    "ContentType":this.base64content['ContentType'],
    "FileDownloadName":this.base64content['FileDownloadName'],
    "projectID":id
  }
  this.mainSubject.reset();
  this.mainSubject.setValue('Compendium Details for Project :'+pname+"("+id+")");

  this.commonService.excel64(obj).subscribe((data) =>{
    if(data['status'].toLowerCase()=='success')
    {
      this.excel64responsebody=data['responsebody'];
        this.excelbase64='data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+data['responsebody'];
      this.filenamedisplay=data['statusmessage'].split(':')[1];
       this.key=data['extendedattributes']['key'];
      let authhtml
       if(this.isftpmail)
       {
         authhtml=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,<br />FTP file password enclosed below.</span></span></span></span></p>
         <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">`+this.key+`</span></span></span></span></p>
         <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Thanks &amp; Regards<br />
         VitalAxis</span></span></span></span></p>`
         this.Subject.setValue("Password to access FTP Details for "+pname+"("+id+")")
       }
       else{
         authhtml=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,<br />Compendium file password enclosed below.</span></span></span></span></p>
         <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">`+this.key+`</span></span></span></span></p>
         <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Thanks &amp; Regards<br />
         VitalAxis</span></span></span></span></p>`
         this.Subject.setValue("Password to access compendium Details for "+pname+"("+id+")")
       }
       let attachmentobjexcel={}
       attachmentobjexcel[this.filenamedisplay]=this.excel64responsebody
       let compMailComponent={
        responsebody:this.excel64responsebody,
        base64:this.excelbase64,
        filenamedisplay:this.filenamedisplay,
        key:this.key,
        toaddresslist:this.alltoaddressid,
        Cclist:this.allCcid ,
        mainSubject:this.mainSubject.value,
        htmlContent:this.htmlContent,
        id:id,
        name:pname,
        authhtml:authhtml,
        Subject:this.Subject.value,
        attachmentobj:attachmentobjexcel
      }

      const modalRef = this.modalService.open(MailComponent,
        { windowClass: "myCustomModalClassFTP", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = compMailComponent;
      modalRef.result.then(() => { });
      }
      else{
        this.toastr.error("Error occured",'',{
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
      })
        this.ngxuiloader.stop();
      }
      this.ngxuiloader.stop();
  },error=>{
    this.ngxuiloader.stop();
  })
}
}



  onTabChangedEdit(value){
    if(value.index !== undefined){
      this.selectedIndexTab= value.index;
    }
  }
  onTabChanged(value){
    this.ShowSubmitButton=false;

     this.Importvalue=value.index;


    let index
    if(value.index !== undefined){
      index = value.index;
      this.selectedIndexTab= value.index;
    }

    if(value.index == undefined){
      index = value
      this.selectedIndexTab = value
    }

    if(value==1)
    {
    this.ShowSubmitButton=true;
    }

    // this.selectedIndexTab= value.index;
    // index = value.index;
    switch (index) {
      case 0:
        this.getInterfaceFormList('Interface')
        break;
      case 1:
        this.getInterfaceFormList('Import')

        break;
      case 2:
        this.getInterfaceFormList('Export')
        break;
      default:
        this.getInterfaceFormList('Interface')
        break;
    }
  }
  units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  niceBytes(x: any) {
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + this.units[l];
  }

  changeStatusProject(projectID:string, status:string,ref){
    this.ngxuiloader.start();
this.commonService.changeImportProjectStatus(projectID.split("-")[1],status).subscribe(data => {
  this.ngxuiloader.stop();
if(data.status.toLowerCase() === "success"){
  ref("close modal");
  this.toastr.success("Changed status to Complete", "", {
    positionClass: "toast-bottom-right",
    timeOut: 4000,
  });
}
},error=>{
  this.ngxuiloader.stop();
  this.toastr.error("Error Occured", "", {
    positionClass: "toast-bottom-right",
    timeOut: 4000,
  });
})
  }

  ngOnDestroy()
  {
    if (this.intervalim) {
      clearInterval(this.intervalim);
   }
    // clearInterval(this.intervalim);
    this.vendorRefresh.unsubscribe();
  }

  copyToClip(event: any, message: string) {
    if (event != "") {
      copyToClipboard(event);
      this.toastr.success(message, "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }
  LogDetails(ApprovalStatus,Pid)
{
    this.service.getApprovalNotes(Pid).subscribe((data)=>
{
  if(data)
  {
   this.NotesDeatils=JSON.parse(data.responsebody);
   this.approvalStatus=status;
  //  this.modalRefpushtoQC = this.modalService.open(ApprovalStatus,
  //    { windowClass: "mappingnotspopup", backdrop: 'static', keyboard: false });
     this.modalRefpushtoQC = this.modalService.open(ApprovalStatus,
      { windowClass: "mappingnote", backdrop: 'static', keyboard: false });
    this.modalRefpushtoQC.result.then(() => { });
  }
})
  }
  sendForApprovalexport(ApprovalStatus,ststus)
  {
    this.ModalTitle = "Creating Export Project"
    this.approvalStatus = ststus;
    if (this.selectedlabExpobj !== undefined) {
      this.LabFormGroupExport.patchValue({ LabExport: this.selectedlabExpobj.Displayname });
    }
    let contactDetailsExp = [];
    if (this.LabFormGroupExport.value.labContactdetailsExport.length > 0) {
      let obs = {
        lab: this.LabFormGroupExport.value.labContactdetailsExport,
      };
      contactDetailsExp.push(obs);
    }
    let obs = {
      requester: [
        {
          name: this.requesterFormGroupExport.value.requesterExpName,
          email: this.requesterFormGroupExport.value.requesterExpEmail,
          phone: this.requesterFormGroupExport.value.requesterExpPhone,
          id: this.reqID !== undefined ? this.reqID : this.reqNoID
        },
      ],
    };
    contactDetailsExp.push(obs);
    let detailExp = JSON.stringify(contactDetailsExp);
    let ExportObj = this.objectForImportAndExport(detailExp, this.selectedlabExpobj, this.requesterFormGroupExport.value.requesterExpName, this.projectFormGroupExport.value.projectExpName, this.projectFormGroupExport.value.projectExpScope, this.VendorFormGroupExport.value.ApplicationNameExp, this.VendorFormGroupExport.value.ApplicationVersionExp, this.vendoIDExp, this.venMnemonicImp, "Export", this.ExpID, this.projExpID, this.LabFormGroupExport.value.lab)
    if (this.LabFormGroupExport.status == "INVALID") {
      this.toastr.warning("required fields are empty:", "Lab Form", {
        positionClass: "toast-bottom-right",
        timeOut: 4000,
      });
    }
    if (this.LabFormGroupExport.controls.labContactdetailsExport.status == "INVALID") {
      this.toastr.warning("Invalid contact details", "Lab Form", {
        positionClass: "toast-bottom-right",
        timeOut: 4000,
      });
    }
    if (this.requesterFormGroupExport.status == "INVALID") {
      this.toastr.warning("required fields are empty:", "Requester Form", {
        positionClass: "toast-bottom-right",
        timeOut: 4000,
      });
    }
    if (this.projectFormGroupExport.status == "INVALID") {
      this.toastr.warning("required fields are empty:", "Project Details Form", {
        positionClass: "toast-bottom-right",
        timeOut: 4000,
      });
    }
    if (
      this.LabFormGroupExport.status != "INVALID" &&
      this.requesterFormGroupExport.status != "INVALID" &&
      this.projectFormGroupExport.status !== "INVALID"
    ) {
      this.commonService.saveInterfaceForm(ExportObj).subscribe(async (data) => {
        if (data) {
          this.projExpID = data.Attributes
          if (data.status == "Success") {
            this.approvalprojectid = this.projectidPid;
            this.modalRefpushtoQC = this.modalService.open(ApprovalStatus,
              { windowClass: "mappingnote", backdrop: 'static', keyboard: false });

            if (this.sendmail) {
              await this.sendprojectchecklist(data.Attributes);
            }
          }
        }
        else {
          this.toastr.error("Error occured", '', {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          })
        }
      });
    }
  }
objectForImportAndExport(detailExp,SelectedlabObj,requesterName,projectName,projectScope,ApplicationName,ApplicationVersion,vendoID,venMnemonic,projecttype,id,ProjID,sourcename)
  {
    let obj = {
      isSubmit: false,
      userId: JSON.parse(sessionStorage.getItem("rolewithmenus"))[0].uniqueId,
      projectID: ProjID !== undefined ? ProjID : "",
      id: id !== undefined ? id : 0,
      projectType: projecttype,
      sourceGuid:
        SelectedlabObj !== undefined
          ?SelectedlabObj.LocationGUID
          : this.isEditLabGUID,
      sourceDeploymentKey: SelectedlabObj !== undefined ? SelectedlabObj.deploymentkey : "",
      sourceOrgId: SelectedlabObj !== undefined ? SelectedlabObj.organizationid : "",
      sourceAccountId: SelectedlabObj !== undefined ? SelectedlabObj.entityid : "",
      sourceName: SelectedlabObj !== undefined ? SelectedlabObj.Displayname  :sourcename,
      sourceOrgName: SelectedlabObj !== undefined ? SelectedlabObj.Displayname : "",
      sourceAccountType: SelectedlabObj !== undefined ? SelectedlabObj.EntityType : "",
      sourceAddress1: SelectedlabObj !== undefined ? SelectedlabObj.Address1 : "",
      sourceAddress2: SelectedlabObj !== undefined ? SelectedlabObj.Address2 : "",
      sourceCity: SelectedlabObj !== undefined ? SelectedlabObj.city : "",
      sourceState: SelectedlabObj !== undefined ? SelectedlabObj.state : "",
      sourceZip: SelectedlabObj !== undefined ? SelectedlabObj.zip : "",
      sourceType: SelectedlabObj !== undefined ? SelectedlabObj.Type : "",
      sourceMnemonic: SelectedlabObj !== undefined ?SelectedlabObj.mnemonic  : "",
      requesterName: requesterName,
      projectName:projectName,
      projectScope: projectScope,
      contactDetails: detailExp,
      vendorID: vendoID,
      vendorName: "",
      vendorMnemonic: venMnemonic,
      appName: ApplicationName,
      appVersion:ApplicationVersion,
    };
    return obj;
  }


  ApproveorReject(ApprovalStatus,status:string,Pid,)
  {
this.content=''
    if(Pid)
    {
      this.projImpID=Pid
    }
    this.approvalStatus=status;
    if (this.isTabName === 'Export') {
      this.ModalTitle =this. getModalTitle('Export', this.approvalStatus);
    } else if (this.isTabName === 'Import') {
      this.ModalTitle =this. getModalTitle('Import', this.approvalStatus);
    }

    this.modalRefpushtoQC = this.modalService.open(ApprovalStatus,
      { windowClass: "mappingnote", backdrop: 'static', keyboard: false });
}
  mappingImport(value) {

    this.improute.projid = value.id
    this.improute.taskid = value.taskId
    this.improute.ftpDetail = value.FTPDetails
    this.router.navigate(['/home/patient', value.id])
  }
  closepopup(c)
  {
    c('close modal');
    this.showApprovalcard=false
    //this.content='';
  }
   async saveNotesDeatils(c, isTabName) {
    if (this.content == '') {
      this.toastr.warning("Please add the Notes!", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right"
      })
      return
    }

    if (this.approvalStatus == 'Manager'|| this.approvalStatus=="approved") {

      if (this.isTabName == 'Import') {
         this.submitImportForm(true);
      }
      if (this.isTabName == 'Export') {
         this.submitExportForm(true);

      }
    }
      if (this.approvalStatus == "Get Approval") {

        if (this.isTabName == 'Import') {
          this.submitImportForm(false);
        }
        if (this.isTabName == 'Export') {
           this.submitExportForm(false);
         }



  }
  if(this.approvalStatus=='rejected')
  {
   if(this.isTabName=='Import')
   {
    this.SaveNotes(this.projImpID,'rejected')
   }
   if(this.isTabName=='Export')
   {
    this.SaveNotes(this.projExpID,'rejected')
   }


    this.toastr.success('Project Rejected successfully', '', {
      positionClass: "toast-bottom-right",
      timeOut: 4000,

    });


  }
  setTimeout(() => {

    c('close modal');
    //this.content = '';
  }, 1000);

}

  uniqueArr(arr, store) {

    let userArr = JSON.parse(JSON.stringify(arr))
    for (let i = 0; i < arr.length; i++) {
      let numbe= userArr.filter(va => va.BatchID == arr[i].BatchID)
      if (numbe.length > 0) {
        let obj = {
          BatchID: arr[i].BatchID,
          count: numbe.length,
          status: arr[i].Status,
          importedon:arr[i].ImportedOn
        }

        userArr = userArr.filter(va => va.BatchID !== arr[i].BatchID)
        store.push(obj)
      }


    }
    return store;
  }

  editPipeline(item) {

    // this.activeModal.close('exampleModalForm');
    if (item.Version == '2') {
      sessionStorage.setItem('pipelineid', JSON.stringify(item.id));
      this.router.navigate(['/home/createpipeline']);
    }
    else {
      const modalRef = this.modalService.open(Routeversion1Component,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = item;
      modalRef.result.then((data) => {
        this.isLockedVer1 = item.IsLocked;
        this.isChecked = false;
        if (data == 'save') {
          this.projInterfacesList(item.projectId);
         }
      });
    }
  }
  openAuditPopUp(id:any,username:any) {

    if (id != '' && username!='') {
      const auditDataToModal = {
        id:id,
        title:username ,
        context: "Project",
        type: "ID",
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };

      const modalRef = this.modalService.open(NewauditComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(() => { });
    }

  }
  downloadCSV()
  {
      const csvUrl = "../../assets/PHI Template.csv";

      this.http
        .get(csvUrl, { responseType: 'blob' })
        .subscribe((blob: Blob) => {

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'PHI Template.csv'; // Change the filename if needed
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });

  }

async getDevicelist() {
      if (this.isdevice) {
        let devicedetails = {}
        devicedetails = {
          "OrgGUID": this.selectedlabobj['orgguid'],
          "Deploymentkey": this.selectedlabobj['deploymentkey'],
          "Direction": 'Inbound'
        }
        this.ngxuiloader.start();
        this.commonService.CreateDeviceConnectivity(devicedetails).subscribe((data) => {
          if (data.responsebody == null || data.responsebody == ''|| data.responsebody == '[]') {
            this.toastr.warning("No devices", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            this.ngxuiloader.stop();
            return ''
          }
          else {
            this.DeviceList = []
            let dlist = JSON.parse(data.responsebody);
            dlist.forEach(element => {
              //let obj = { 'DeviceName': element }
              let obj = { 'DeviceName': element.InstrumentCodes,
              'MessageVersion': element.MessageVersion,
              'MessageFormat' :element.MessageFormat,
              'DeviceType': element.Devicetype,
              'ConnectivityType': element.ConnectivityType,
              'BufferSize': element.BufferSize,
              'SendReflexTestOrder': element.SendReflexTestOrder,
              'ReflexDeviceId': element.ReflexDeviceId,
              'ReflexDeviceName': element.ReflexDeviceName,
              'CheckSumType': element.CheckSumType,
              'ModelInfo': element.ModelInfo
               }
              this.DeviceList.push(obj); 
            })
            this.DeviceListbackup = JSON.parse(JSON.stringify(this.DeviceList));
            this.ngxuiloader.stop();
            return this.DeviceList
          }

        }, error => {
          this.toastr.error("something went wrong in getting device list", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          this.ngxuiloader.stop();
        })
      }
      else{
        return ''
      }
  }

  async Getvbagentsdetails() {
    if (this.selectedlabobj['LocationGUID'] != '' && this.selectedlabobj['LocationGUID'] != undefined) {
    this.ngxuiloader.start();
    this.commonService.Getvbagentsdetails(this.selectedlabobj['LocationGUID']).subscribe((data) => {
      if (data.status == 'success') {
        let obj = JSON.parse(data['responsebody']);
        this.vbagentlist = obj['content'];
        let agID = this.vbagentlist[0].AgentID;
        this.vbagentlistbackup = obj['content'];
      }
      this.ngxuiloader.stop();
      return this.vbagentlist;
    }, error => {
      this.ngxuiloader.stop();
    })
  }
  }


  DownloadUserGuid()
  {
    this.commonService.getPdf("userguid").subscribe((data)=>
    {

      if(data)
      {
          const byteCharacters = atob(data.statusmessage);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          const objectUrl = URL.createObjectURL(blob);

          // Create an anchor element and programmatically click it to trigger the download
          const anchor = document.createElement('a');
          anchor.href = objectUrl;
          anchor.download = 'DataImport_UserGuildline.pdf';
          anchor.click();

          // Clean up the object URL to avoid memory leaks
          URL.revokeObjectURL(objectUrl);

      }
    })

  }

  async sendprojectchecklist(id) {

    let htmlContent = `<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,</span></span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Please find the attached file for Import project check list Information. </span></span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>Thanks &amp; Regards</strong></span></span></strong></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>VitalAxis</strong></span></span></strong></span></span></p>`;


    this.commonService.getPdf("import").subscribe(async (data)=>
    {

      if(data)
      {

        let Attachment = {
          [`ImportProjectCheckList.pdf`]: data.statusmessage
        };

        let ccobj = {}
        let obj = {
          "to": this.mails,
          "cc": ccobj,
          "from_email": this.fromaddress,
          "attachment": Attachment,
          "html": htmlContent,
          "subject": "Import Project CheckList For " + "(" + id + ")"
        }
        await this.commonService.mailapi(obj).subscribe((data) => {
          if (data['status'].toLowerCase() == 'success') {
            this.toastr.success('Mail sent successfully', '', {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
          }
          else {
            this.toastr.error("Failed", 'Sending mail', {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
        });

      }
    });

  }
  async sendExportProjectCheckist(id) {
   let htmlContent = `<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,</span></span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Please find the attached file for Export project check list Information. </span></span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>Thanks &amp; Regards</strong></span></span></strong></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>VitalAxis</strong></span></span></strong></span></span></p>`;


this.commonService.getPdf("export").subscribe(async (data)=>
{

  if(data)
  {
    let Attachment = {
      [`ExportProjectCheckList.pdf`]: data.statusmessage
    };
    let ccobj = {}
    let obj = {
      "to":this.mails,
      "cc": ccobj,
      "from_email": this.fromaddress,
      "attachment": Attachment,
      "html": htmlContent,
      "subject": "Export Project CheckList For " + "(" + id + ")"
    }
    await this.commonService.mailapi(obj).subscribe((data) => {
      if (data['status'].toLowerCase() == 'success') {
        this.toastr.success('Mail sent successfully', '', {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      else {
        this.toastr.error("Failed", 'Sending mail', {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    });

  }
});

  }


  async SaveNotes(id,from)
  {

    let approvalStatusResponse
    let obj: any
    let role = "";
    if (this.pagerolepermisions.ApproveAndreject) {
      role = "Manager";
    }
    else {
      role = "Qc";
    }
    if (this.isTabName == 'Import') {
      obj = {
        projectId: id,
        Notes: this.content,
        UserId: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
        Role: role,
        ApprovalStatus: from == "Manager" || from == "approved" ? "submit" : from,
        TaskType: this.isTabName
      };
    }
    if (this.isTabName == 'Export') {
      obj = {
        projectId: id,
        Notes: this.content,
        UserId: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
        Role: role,
        ApprovalStatus: from == "Manager" || from == "approved" ? "submit" : from,
        TaskType: this.isTabName
      };
    }
    this.service.SaveApprovalnotes("api/PatientHistoryImport/SaveNoteDeatils", obj).toPromise().then(async response => {

      if (response) {

        approvalStatusResponse = response['responsebody']


        if (approvalStatusResponse == "rejected") {
          this.ShowApproveAndRejectButton = false;
          await this.closeCreateProject()
        }
      }
      else {
        this.toastr.error("Unable save", "Notes Deatils", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      this.ngxuiloader.stop();
    });


  }
  saveProjects(isform) {
    this.approvalStatus = isform
    if (this.isTabName == 'Import') {
      this.submitImportForm(false)
    }
    if (this.isTabName == 'Export') {
      this.submitExportForm(false)
    }
  }

  getModalTitle(tabName: string, approvalStatus: string): string {
    if (approvalStatus === 'Get Approval' || approvalStatus === 'Manager') {
      return `Creating ${tabName} Project`;
    }

    return approvalStatus === 'rejected'
      ? `Rejecting ${tabName} Project`
      : `Approving ${tabName} Project`;
  }

  projInterfacesList(value : any){

    let prid = value

      let obj ={
        "messagetype": "",
        "id": "",
        "PAID": "",
        "sourceagentid": "",
        "SEGUID": "",
        "destinationagentid": "",
        "DEGUID": "",
        "vendor": "",
        "entityid": "",
        "pagenumber": 1,
        "userType": "",
        "type":'not',
        "projectId":prid,
        "assignedUserId": "",
        "Connectivity":this.viewform['interfaceBetween'].includes('Device') ? 'Device' : ""
      }

      this.commonService.getProjInterfaces(obj).subscribe((data)=>{

        if(data){
          this.interlist = data
        }
      })
  }
}



